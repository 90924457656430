import { isPast } from 'date-fns';
import { AddressData, BrowserInfo } from './common';
import { AttachedFile } from './fileRm';

export type MangoPayCardType = "CB_VISA_MASTERCARD" | "DINERS" | "MASTERPASS" | "MAESTRO" | "P24" | "IDEAL" | "BCMC" | "PAYLIB";

export type CreditCardTypeCardBrandNiceType = "American Express" | "Diners Club" | "Discover" | "Elo" | "Hiper" | "Hipercard" | "JCB" | "Maestro" | "Mastercard" | "Mir" | "UnionPay" | "Visa";

export enum PaymentMethods {
    Mandate = "mango_mandate",
    CreditCard = "mango_credit_card"
}
export interface EpaymentProcedureBody {
    paymentId: string;
    landlordId: string;
    amount: number;
    paymentMethodId?: string;
    paymentMethod: PaymentMethods
    overrideMandateId?: string //deprecated, substitution already in place, need to remove once old versions of places are out of date
	saveCCInfo?: boolean
	browserInfo?: BrowserInfo
	historyLength?: number
	idempotencyKey?: string
	recurringPayment?: boolean
}
export interface CardRegistrationReturnObject {
    id: string
    preRegistrationData: string,
    accessKey: string,
    cardRegistrationURL: string
}
export interface UserPaymentInfoProfileData {
    birthDayUtcSeconds: number;
    countryOfResidence: string;
    email: string;
    firstName: string;
    lastName: string;
    nationality: string;
}

export interface UserPaymentInfoBankAccountData {
    address: AddressData;
    
    benificiaryName: string;
    iban: string;
    type: string;
}
export interface CreditCard {
    status: "error" | "created",
    mangoPay: {
        cardRegistrationId: string
        cardId: string
    }
    last4Digits: string
    id: string
	associatedRecurringIds?: string[]
}

export interface PaymentDetails {
	feesAmount: number;
	netFeeAmount: number;
	totalAmount: number;
	vatRate: number;
	feesType: CreditCardFeesType;
	feeRate: number;
}

export type UserInfo = {
	status: string;
	mangopay?: {
		userProfileId?: string;
		walletId?: string;
	};
	data : UserPaymentInfoProfileData
}

export type BankAccountInfo = {
	status: string;
	mangopay?: {
		bankAccountId: string;
	};
	data: UserPaymentInfoBankAccountData;
}

export type KycInfo = {
	status: string;
	errorReasonCode?: string;
}

export type MandateInfo = {
	status: string;
	mangopay?: {
		mandateId: string;
		expiryTimeInSeconds: number;
		redirectUrl: string;
		creationTimeInSeconds: number;
	};
}

export type CreditCardInfo = {
	prefferedCreditCard?: string,
	creditCards?: {
		[key: string]: CreditCard
	}
}

export interface UserPaymentInfo {
    userInfo: UserInfo
    bankAccountInfo: BankAccountInfo,
    kycInfo?: KycInfo
    mandateInfo?: MandateInfo
	creditCardInfo: CreditCardInfo
}


export declare type CreditCardPolicies = 'none' | 'all' | 'only-expenses' | 'only-rent';
export declare type BankMandatePolicies = 'none' | 'all' | 'only-expenses' | 'only-rent';
export declare type CreditCardFeesType = 'default' | 'tenant';
export interface EpaymentPreferences {
  creditCardPolicy: CreditCardPolicies;	
  bankMandatePolicy: BankMandatePolicies;
  creditCardFeesType: CreditCardFeesType;
}

export class TaxInfo {
	constructor(
		public vatCode: string,
		public vatValue: number,
		public description: string,
		public exlusiveOf?: string[],
		public natura?: string
	) {}

	public static NONE(): TaxInfo {
		return new TaxInfo('6', 0, '');
	}
}

export interface DatedItem {
	createdDate?: number;
	updatedDate?: number;
	createdBy?: string;
	updatedBy?: string;
}
export interface GenericInvoice extends DatedItem {
	id?: string;
	invoiceNumber: number;
	invoiceDate: string;
	name?: string;
	type: string;
	status: 'waiting' | 'ready' | 'error';
	message?: string;
	url?: string;
	referenceCode?: string;
	[key: string]: any; // TODO: Features in definition
}

export enum PaymentProviderRecurringPaymentStatus {
    ACTIVE = 'active',
    AUTH_NEEDED = 'auth_needed',
    INVALID = 'invalid',
}

export interface RecurringPayment extends DatedItem {
	id: string
	endTime: number;
    paymentProviderAssociation?: {
        id: string,
        amount: number,
        status: PaymentProviderRecurringPaymentStatus,
		associatedCardId: string,
    }
}

export enum PaymentMethod {
    OTHER = "other",
    CASH = "cash",
    BANKWIRE = "bankwire",
    DEPOSIT_IN = "deposit_in",
    CARD = "card",
    MANGO_MANDATE = "mango_mandate",
    MANGO_CREDIT_CARD = "mango_credit_card"
}

export class PaymentItem {
	constructor(
		public code: string,
		public name: string,
		public amount: number,
		public category: string,
		public linkedId?: string,
		public taxInfo?: TaxInfo,
		public excludeInvoice?: boolean,
		public description?: string
	) {}
}

export class PropertyPayment {
	unitId?: string;

	invoices?: { [key: string]: { [key: string]: GenericInvoice } };

	closingDate?: number;

	bookingId?: string;
	recurringPaymentStatus?: PaymentProviderRecurringPaymentStatus

	constructor(
		public title: string, // Title (unused if we show category, property and tenant)
		public category: string, // ID of category
		public creationDate: number, // Date of creation of this payment
		public referenceDate: number,
		public dueDate: number, // Expiry of this payment
		public amount: number, // Amount of transaction
		public currency: string, // Currency of the amount
		public tenantId: string, // TENANT
		public propertyId: string, // PROPERTY
		public leaseId: string, // LEASE
		public landlordId: string,
		public note: string,
		public attachments: AttachedFile[],
		public authorId: string,
		public linkedPeriodicPaymentId: string,
		public status: PaymentsStatus,
		public id: string,
		public amountPaid: number,
		public extraFees: PaymentItem[],
		public metadata: {
			[key: string]: string | number | boolean;
		},
		public allowedPaymentMethods: PaymentMethod[]
	) {}

	static getStatusFromPayment(payment: PropertyPayment) {
		if(payment.status === 'blocked' || payment.status === 'inactivate'){
			return payment.status
		}
		if (payment.metadata && payment.metadata['lockedBy']) {
			if (payment.metadata['lockerType'] === 'intent') {
				return 'started';
			}

			return 'pending';
		} else {
			if (isPast(payment.dueDate) && payment.status === 'open') {
				return 'overdue';
			} else {
				return payment.status;
			}
		}
	}
}

export class PaymentOperation {
	constructor(
		public id: string,
		public linkedPaymentId: string,
		public amount: number,
		public currency: string,
		public createdDate: number,
		public completionTime: number,
		public paymentMethod: string,
		public linkedPaymentMethodOpId: string,
		public status: OperationStatus,
		public authorId: string,
		public landlordId: string,
		public fee: number,
		public failureReason?: string,
		public metadata?: {
			secureModeRedirectUrl: string
		}
	) {}
}

export declare type PaymentsStatus = 'open' | 'close' | 'failed' | 'succeeded' | 'blocked' | 'inactivate';
export declare type OperationStatus = 'pending' | 'failure' | 'late_failure' | 'succeeded' | 'started';

