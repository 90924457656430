<div *ngIf="!isLoading" fxLayout="row" fxLayoutAlign="center flex-start">

    <div fxLayout="column" fxLayoutAlign="flex-start center" class="payment-main-content" [ngClass]="{'payment-main-content-desk': !mobile, 'payment-main-content-mobile': mobile }">

        <div *ngIf="!mobile" fxLayout="row" fxLayoutAlign="flex-start center" class="top-row">
            <img class="img-back" src="../../assets/img/rm_icon_arrow_left.svg" (click)="backClick()" />
            <p class="regular-20 no-margin" [innerHTML]="'screenTitles.payment_title' | translate"></p>
        </div>
        <div *ngIf="mobile" fxLayout="row" fxLayoutAlign="flex-start center" class="top-row-mobile">
            <p class="regular-15" [innerHTML]="'screenTitles.payment_title_2' | translate"></p>
        </div>

        <div [ngClass]="{'page-inner-content-desk': !mobile, 'page-inner-content-mobile': mobile}">
            <div *ngIf="!mobile" fxLayout="column" fxLayoutAlign="flex-start flex-start" style="width: 100%;">
                <p class="semi-bold-15 no-margin">{{ 'com_details' | translate }}</p>
                <div class="sep-row-light"></div>
    
                <mat-card class="no-padding info-table">
                    <table mat-table [dataSource]="[payment]" style="z-index: 10">
                        <ng-container matColumnDef="title">
                            <th mat-header-cell *matHeaderCellDef>{{ 'com_up_title' | translate }}</th>
                            <td mat-cell *matCellDef="let p" style="white-space: nowrap">
                                <p class="regular-12 no-margin">{{ getPaymentTitle(p) }}</p>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="status">
                            <th mat-header-cell *matHeaderCellDef>{{ 'com_up_status' | translate }}</th>
                            <td mat-cell *matCellDef="let p" style="white-space: nowrap">
                                <div *ngIf="getStatus(p) === 'open'" class="rm-tag-view tag-primary">
                                    <p class="semi-bold-12 color-white">{{ 'pay_status_open' | translate }}</p>
                                </div>
                                <div *ngIf="getStatus(p) === 'started'" class="rm-tag-view tag-primary">
                                    <p class="semi-bold-12 color-white">{{ 'pay_status_started' | translate }}</p>
                                </div>
                                <div *ngIf="getStatus(p) === 'pending'" class="rm-tag-view tag-primary">
                                    <p class="semi-bold-12 color-white">{{ 'pay_status_pending' | translate }}</p>
                                </div>
                                <div *ngIf="getStatus(p) === 'close' || getStatus(p) === 'succeeded'" class="rm-tag-view tag-green">
                                    <p class="semi-bold-12 color-white">{{ 'pay_status_paid' | translate }}</p>
                                </div>
                                <div *ngIf="getStatus(p) === 'overdue'" class="rm-tag-view tag-red">
                                    <p class="semi-bold-12 color-white">{{ 'pay_status_overdue' | translate }}</p>
                                </div>
                                <div *ngIf="getStatus(p) === 'blocked'" class="rm-tag-view tag-loading">
                                    <p class="semi-bold-12 color-white">{{ 'pay_status_blocked' | translate }}</p>
                                </div>
                                <div *ngIf="getStatus(p) === 'inactivate'" class="rm-tag-view tag-refusal">
                                    <p class="semi-bold-12 color-white">{{ 'pay_status_refusal' | translate }}</p>
                                </div>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="amount">
                            <th mat-header-cell *matHeaderCellDef>{{ 'com_up_amount' | translate }}</th>
                            <td mat-cell *matCellDef="let p" style="white-space: nowrap">
                                <p class="semi-bold-12 no-margin">{{ p.amount / 100 | currency: p.currency }}</p>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="paid">
                            <th mat-header-cell *matHeaderCellDef>{{ 'com_up_paid' | translate }}</th>
                            <td mat-cell *matCellDef="let p" style="white-space: nowrap">
                                <p *ngIf="p.amountPaid === 0" class="regular-12 no-margin">{{ p.amountPaid / 100 | currency: p.currency }}</p>
                                <p *ngIf="p.amountPaid > 0" class="semi-bold-12 color-green no-margin">{{ p.amountPaid / 100 | currency: p.currency }}</p>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="dueDate">
                            <th mat-header-cell *matHeaderCellDef>{{ 'com_up_due_date' | translate }}</th>
                            <td mat-cell *matCellDef="let p" style="white-space: nowrap">
                                <p class="regular-12 no-margin">{{ p.dueDate | dfnsFormat: 'd MMM yyyy' }}</p>
                            </td>
                        </ng-container>
                        
                        
                        <ng-container *ngIf="payment?.category === 'rent'" matColumnDef="rentPeriod">
                            <th mat-header-cell *matHeaderCellDef>{{ 'com_up_rent_period' | translate }}</th>
                            <td mat-cell *matCellDef="let p" style="white-space: nowrap">
                                <p class="regular-12 no-margin" [innerHTML]="'rent_from_x_to_y' | translate:{ rentStart: getDateStr(rentStart), rentEnd : getDateStr(rentEnd) }">
                                </p>
                            </td>
                        </ng-container>
    
                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns"
                        ></tr>
                    </table>
                </mat-card>
            </div>
    
            <div *ngIf="mobile" fxLayout="column" fxLayoutAlign="flex-start flex-start" style="width: 100%;">
                <app-dash-label-simple fxFlex="100%" [label]="'com_title' | translate">{{ getPaymentTitle(payment) }}</app-dash-label-simple>
            
                <p class="semi-bold-12 color-grey v-spacing-20" style="margin-bottom: 5px; font-weight: 600;">{{ 'com_status' | translate }}</p>
                <div style="width: min-content;">
                    <div *ngIf="getStatus(payment) === 'open'" class="rm-tag-view tag-primary">
                        <p class="semi-bold-12 color-white">{{ 'pay_status_open' | translate }}</p>
                    </div>
                    <div *ngIf="getStatus(payment) === 'started'" class="rm-tag-view tag-primary">
                        <p class="semi-bold-12 color-white">{{ 'pay_status_started' | translate }}</p>
                    </div>
                    <div *ngIf="getStatus(payment) === 'pending'" class="rm-tag-view tag-primary">
                        <p class="semi-bold-12 color-white">{{ 'pay_status_pending' | translate }}</p>
                    </div>
                    <div *ngIf="getStatus(payment) === 'close' || getStatus(payment) === 'succeeded'" class="rm-tag-view tag-green">
                        <p class="semi-bold-12 color-white">{{ 'pay_status_paid' | translate }}</p>
                    </div>
                    <div *ngIf="getStatus(payment) === 'overdue'" class="rm-tag-view tag-red">
                        <p class="semi-bold-12 color-white">{{ 'pay_status_overdue' | translate }}</p>
                    </div>
                </div>
    
                <app-dash-label-simple fxFlex="100%" [label]="'com_amount' | translate" class="v-spacing-20">{{ (payment?.amount || 0) / 100 | currency: payment?.currency }}</app-dash-label-simple>
                <app-dash-label-simple fxFlex="100%" [label]="'com_paid' | translate" class="v-spacing-20">{{ (payment?.amountPaid || 0) / 100 | currency: payment?.currency }}</app-dash-label-simple>
    
                <app-dash-label-simple fxFlex="100%" [label]="'com_due_date' | translate" class="v-spacing-20">{{ payment?.dueDate | dfnsFormat: 'd MMM yyyy' }}</app-dash-label-simple>
                <app-dash-label-simple *ngIf="payment?.category === 'rent'" fxFlex="100%" [label]="'com_rent_period' | translate" class="v-spacing-20">
                    <span [innerHTML]="'rent_from_x_to_y' | translate:{ rentStart: getDateStr(rentStart), rentEnd : getDateStr(rentEnd) }"></span>
                </app-dash-label-simple>
            </div>

            <p *ngIf="payment.bookingId && getStatus(payment) === 'inactivate'" 
                class="regular-15 v-spacing-20">{{ 'inactivate_booking_payment' | translate }}
            </p>

            <p *ngIf="payment.bookingId && getStatus(payment) === 'blocked'" 
                class="regular-15 v-spacing-20">{{ 'blocked_booking_payment' | translate }}
            </p>

            <div *ngIf="showCreditCardInfo && (getStatus(payment) === 'open' || getStatus(payment) === 'overdue')" fxLayout="column" fxLayoutAlign="flex-start flex-start" style="width: 100%; margin: 30px 0 0 0;">
                <p class="semi-bold-15 no-margin">{{ 'com_CreditCard' | translate }}</p>
                <p class="regular-12 no-margin">{{ 'creditCard_Info' | translate }}</p>
                <div class="sep-row-light"></div>

                <div *ngIf="!mobile && creditCards.length === 0" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="20px" style="width: 100%; margin-bottom: 20px;">
                    <mat-form-field appearance="outline" class="field-full-width" hideRequiredMarker>
                        <mat-label>{{ 'cardNumber' | translate }}  <span *ngIf="type$ | async as type" class="cc-brand">[{{type}}]</span></mat-label>
                        <input
                            name="cardNumber"
                            type="text"
                            matInput
                            [formControl]="cardNumber"
                            style="text-align: left;"
                            (keypress)="amountKeyPressValidate($event)"
                            autocomplete="cc-number"
                            [placeholder]="'•••• •••• •••• ••••'"
                            maxlength="19"
                            />
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="field-full-width" hideRequiredMarker>
                        <mat-label>{{ 'cardHolder_Name' | translate }}</mat-label>
                        <input
                            name="name"
                            type="text"
                            matInput
                            [formControl]="cardHolderName"
                            autocomplete="cc-name"
                            style="text-align: left;"
                            [placeholder]="'Name'"
                            maxlength="30"
                        />
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="field-full-width" hideRequiredMarker>
                        <mat-label>{{ 'expiration_Date' | translate }}</mat-label>
                        <input
                            name="expDate"
                            (keyup)="onKey($event)"
                            type="text"
                            matInput
                            [formControl]="expirationDate"
                            style="text-align: left;"
                            autocomplete="cc-exp"
                            [placeholder]="'MM/YY'"
                            maxlength="5"
                        />
                    </mat-form-field> 
                    <mat-form-field appearance="outline" class="field-full-width" hideRequiredMarker>
                        <mat-label>{{ 'CCV/CVC' | translate }}</mat-label>
                        <input
                            name="cvc"
                            type="text"
                            matInput
                            [formControl]="ccv"
                            style="text-align: left;"
                            [placeholder]="'•••'"
                            autocomplete="cc-cvc"
                            maxlength="4"
                        />
                    </mat-form-field>
                </div>

                <div *ngIf="mobile && creditCards.length === 0" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px" style="width: 100%; margin-bottom: 20px;">
                    <mat-form-field appearance="outline" class="field-full-width" hideRequiredMarker>
                        <mat-label>{{ 'cardNumber' | translate }}  <span *ngIf="type$ | async as type" class="cc-brand">[{{type}}]</span></mat-label>
                        <input
                            name="cardNumber"
                            type="text"
                            matInput
                            [formControl]="cardNumber"
                            style="text-align: left;"
                            (keypress)="amountKeyPressValidate($event)"
                            autocomplete="cc-number"
                            [placeholder]="'•••• •••• •••• ••••'"
                            maxlength="19"
                            />
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="field-full-width" hideRequiredMarker>
                        <mat-label>{{ 'cardHolder_Name' | translate }}</mat-label>
                        <input
                            name="name"
                            type="text"
                            matInput
                            [formControl]="cardHolderName"
                            autocomplete="cc-name"
                            style="text-align: left;"
                            [placeholder]="'Name'"
                            maxlength="30"
                        />
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="field-full-width" hideRequiredMarker>
                        <mat-label>{{ 'expiration_Date' | translate }}</mat-label>
                        <input
                            name="expDate"
                            (keyup)="onKey($event)"
                            type="text"
                            matInput
                            [formControl]="expirationDate"
                            style="text-align: left;"
                            autocomplete="cc-exp"
                            [placeholder]="'MM/YY'"
                            maxlength="5"
                        />
                    </mat-form-field> 
                    <mat-form-field appearance="outline" class="field-full-width" hideRequiredMarker>
                        <mat-label>{{ 'CCV/CVC' | translate }}</mat-label>
                        <input
                            name="cvc"
                            type="text"
                            matInput
                            [formControl]="ccv"
                            style="text-align: left;"
                            [placeholder]="'•••'"
                            autocomplete="cc-cvc"
                            maxlength="4"
                        />
                    </mat-form-field>
                </div>

                <div *ngIf="creditCards.length > 0" fxLayout="column" fxLayoutAlign="flex-start flex-start" fxLayoutGap="20px" style="width: 100%; margin-bottom: 20px;">
                    <p class="semi-bold-15 no-margin" >{{ 'cardNumber' | translate }}</p>
                    <p class="regular-12 no-margin">{{ creditCards[0].last4Digits }}</p>
                </div>

                <p *ngIf="creditCards.length > 0" class="regular-12 underlined" (click)="navigateToPaymentsSettings()" style="margin-bottom: 50px; color: #99A3AB;">{{ 'change_CC_Info' | translate }}</p>

                <mat-checkbox *ngIf="creditCards.length === 0" (change)="saveCCInfoCheckBoxEvent($event)" style="margin-bottom: 10px;" [checked]="saveCCInfo">
                    <mat-label>{{"save_cc_info" | translate }}</mat-label>
                </mat-checkbox>

                <mat-checkbox *ngIf="linkedRecurring && (!linkedRecurring.paymentProviderAssociation || (linkedRecurring && linkedRecurring.paymentProviderAssociation && linkedRecurring.paymentProviderAssociation.status === paymentProviderRecurringPaymentStatus.INVALID && linkedRecurring.endTime > now))" (change)="makeRecurringCheckBoxEvent($event)" style="margin-bottom: 50px;" [checked]="recurring">
                    <mat-label>{{"make_recurring" | translate }}</mat-label>
                </mat-checkbox>

                <p class="semi-bold-15 no-margin">{{ 'com_transaction' | translate }}</p>
                <p class="regular-12 no-margin">{{ 'payment_transaction_info' | translate }}</p>

                <div class="sep-row-light"></div>

                <div fxLayout="column" fxLayoutAlign="center center" style="width: 100%;">
                    <button mat-button
                        style="min-height: 40px;" 
                        class="button-green button-pay" 
                        [ngClass]="{'button-positive': (paymentField.valid && +paymentField.value >= 1), 'button-neutral': (!paymentField.valid  || +paymentField.value < 1)}" 
                        (click)="payPayment(paymentMethods.CreditCard)"
                    >{{ 'confirm_cc_payment' | translate }}</button>
                </div>
            </div>
    
            <div *ngIf="(getStatus(payment) === 'open' || getStatus(payment) === 'overdue') && !setupNeeded && landlordEpaymentsConfigured && !showCreditCardInfo" fxLayout="column" fxLayoutAlign="flex-start flex-start" style="width: 100%; margin: 30px 0 0 0;">
                <p class="semi-bold-15 no-margin">{{ 'com_transaction' | translate }}</p>
                <p class="regular-12 no-margin">{{ 'payment_transaction_info' | translate }}</p>
                <div class="sep-row-light"></div>
                
                <div *ngIf="!mobile" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px" style="width: 100%; margin-bottom: 30px;">
                    <div>
                        <mat-form-field appearance="outline" class="field-full-width" hideRequiredMarker>
                            <mat-label>{{ 'amount_to_be_paid' | translate }}</mat-label>
                            <input
                                type="text"
                                matInput
                                [formControl]="paymentField"
                                autocomplete="off"
                                style="text-align: center;"
                                (keypress)="amountKeyPressValidate($event)"
								maxlength="8"
								[placeholder]="'0.00 ' + currencySymbol + ' '"
                            />
                        </mat-form-field>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="20px">
                        <button 
                            *ngIf="showCCButton()"
                            [matTooltip]="getPaymentBlockedTooltip()"
                            [matTooltipDisabled]="!blockedByNonProcessedPaymentIntent && +paymentField.value <= amountLeftToPay"
                            [disabled]="+paymentField.value > amountLeftToPay || blockedByNonProcessedPaymentIntent"
                            mat-button class="button-pay"
                            [ngClass]="{'button-positive': (paymentField.valid && +paymentField.value >= 1), 'button-neutral': (!paymentField.valid  || +paymentField.value < 1)}" 
                            (click)="proceedCCPayment()">{{ 'com_pay_CreditCard' | translate }}
                        </button>
                        <button
                            *ngIf="showMandateButton()" 
                            mat-button class="button-pay" 
                            [matTooltip]="getPaymentBlockedTooltip()" 
                            [matTooltipDisabled]="!activeLinkedRecurring && !blockedByNonProcessedPaymentIntent && +paymentField.value <= amountLeftToPay"
                            [disabled]="activeLinkedRecurring || blockedByNonProcessedPaymentIntent || +paymentField.value > amountLeftToPay"
                            [ngClass]="{'button-primary': (paymentField.valid && +paymentField.value >= 1), 'button-neutral': (!paymentField.valid  || +paymentField.value < 1)}" 
                            (click)="proceedMandatePayment()">{{ 'com_pay_Mandate' | translate }}
                        </button>
                    </div>
                    
                </div>
                <div *ngIf="mobile" fxLayout="row" fxLayoutAlign="center flex-start" style="width: 100%; margin-bottom: 10px;">
                    <div fxFlex="100%">
                        <mat-form-field appearance="outline" class="field-full-width" hideRequiredMarker>
                            <mat-label>{{ 'amount_to_be_paid' | translate }}</mat-label>
                            <input
                                type="text"
                                matInput
                                [formControl]="paymentField"
                                autocomplete="off"
                                style="text-align: center;"
                                (keypress)="amountKeyPressValidate($event)"
								maxlength="8"
								[placeholder]="'0.00 ' + currencySymbol + ' '"
                            />
                        </mat-form-field> 
                    </div>
                </div>
                <div *ngIf="mobile" fxLayout="column" fxLayoutAlign="center center" style="width: 100%; margin-bottom: 30px;">
                    <button 
                        mat-button class="button-pay" 
                        *ngIf="showCCButton()"
                        [matTooltip]="getPaymentBlockedTooltip()"
                        [matTooltipDisabled]="!blockedByNonProcessedPaymentIntent && +paymentField.value <= amountLeftToPay"
                        [disabled]="+paymentField.value > amountLeftToPay || blockedByNonProcessedPaymentIntent"
                        style="margin-top: 20px;" 
                        [ngClass]="{'button-positive': (paymentField.valid && +paymentField.value >= 1), 'button-neutral': (!paymentField.valid  || +paymentField.value < 1)}" 
                        (click)="proceedCCPayment()">{{ 'com_pay_CreditCard' | translate }}
                    </button>
                    <button 
                        mat-button class="button-pay" 
                        *ngIf="showMandateButton()"
                        [matTooltip]="getPaymentBlockedTooltip()" 
                        [matTooltipDisabled]="!activeLinkedRecurring && !blockedByNonProcessedPaymentIntent && +paymentField.value <= amountLeftToPay"
                        [disabled]="activeLinkedRecurring || blockedByNonProcessedPaymentIntent || +paymentField.value > amountLeftToPay"
                        [ngClass]="{'button-primary': (paymentField.valid && +paymentField.value >= 1), 'button-neutral': (!paymentField.valid  || +paymentField.value < 1)}" 
                        (click)="proceedMandatePayment()">{{ 'com_pay_Mandate' | translate }}
                    </button>
                </div>
            </div>
            <div *ngIf="(getStatus(payment) === 'open' || getStatus(payment) === 'overdue') && setupNeeded && landlordEpaymentsConfigured" fxLayout="column" fxLayoutAlign="flex-start flex-start" style="width: 100%; margin: 30px 0 0 0;">
                <p class="semi-bold-15 no-margin">{{ 'com_transaction' | translate }}</p>
                <p class="regular-12 no-margin">{{ 'payment_transaction_info' | translate }}</p>
                <div class="sep-row-light"></div>

                <div class="payments-landlord-not-configured">
                    <p class="regular-15 text-center" [innerHTML]="'tenant_epayments_not_configured' | translate:{tenantNameSurname: tenantNameSurname}">
                    </p>
                </div>

                <div fxLayout="row" fxLayoutAlign="center center" style="width:  100%; margin-top: 30px;">
                    <button mat-button class="button-orange button-small" (click)="setupEPayments()">{{ 'cta_payments_setup' | translate }}</button>
                </div>
            </div>


            <div *ngIf="attachments.length > 0" fxLayout="column" fxLayoutAlign="flex-start flex-start" style="width: 100%; margin: 30px 0 0 0;">
                <p class="semi-bold-15 no-margin">{{ 'com_attached_files' | translate }}</p>
                <div class="sep-row-light"></div>
    
                <app-file-picker [files]="attachments" [showUploadTime]="false"></app-file-picker>
            </div>
    
            <div *ngIf="!mobile && operations.length > 0" fxLayout="column" fxLayoutAlign="flex-start flex-start" style="width: 100%; margin: 30px 0 40px 0;">
                <p class="semi-bold-15 no-margin">{{ 'com_payment_operations' | translate }}</p>
                <div class="sep-row-light"></div>
    
                <mat-card class="no-padding">
                    <table mat-table [dataSource]="operations" style="z-index: 10">
                        <ng-container matColumnDef="date">
                            <th mat-header-cell *matHeaderCellDef>{{ 'com_up_date' | translate }}</th>
                            <td mat-cell *matCellDef="let o" style="white-space: nowrap">
                                <p class="regular-12 no-margin">{{ o.createdDate | dfnsFormat: 'd MMM yyyy' }}</p>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="amount">
                            <th mat-header-cell *matHeaderCellDef>{{ 'com_up_amount' | translate }}</th>
                            <td mat-cell *matCellDef="let o" style="white-space: nowrap">
                                <p class="regular-12 no-margin">{{ o.amount / 100 | currency: o.currency }}</p>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="status">
                            <th mat-header-cell *matHeaderCellDef>{{ 'com_up_status' | translate }}</th>
                            <td mat-cell *matCellDef="let o" style="white-space: nowrap">
                                <div *ngIf="o.status === 'started'" class="rm-tag-view tag-primary">
                                    <p class="semi-bold-12 color-white">{{ 'pay_status_started' | translate }}</p>
                                </div>
                                <div *ngIf="o.status === 'pending'" class="rm-tag-view tag-primary">
                                    <p class="semi-bold-12 color-white">{{ 'pay_status_pending' | translate }}</p>
                                </div>
                                <div *ngIf="o.status === 'failure' || o.status === 'late_failure'" class="rm-tag-view tag-red">
                                    <p class="semi-bold-12 color-white">{{ 'pay_status_failed' | translate }}</p>
                                </div>
                                <div *ngIf="o.status === 'succeeded'" class="rm-tag-view tag-green">
                                    <p class="semi-bold-12 color-white">{{ 'pay_status_paid' | translate }}</p>
                                </div>
                                <div *ngIf="o?.metadata?.secureModeRedirectUrl && o?.status === 'pending'" fxLayout="column" fxLayoutAlign="flex-start flex-start">
                                    <span (click)="on3DSLinkPressed(o)">{{ 'cc_payments_redirect_label' | translate }}</span>
                                </div>
                            </td>
                            
                        </ng-container>
                        
                        <ng-container matColumnDef="info">
                            <th mat-header-cell *matHeaderCellDef></th>
                            <td mat-cell *matCellDef="let o" style="white-space: nowrap">
                                <p *ngIf="!!o.failureReason && o.failureReason !== ''" class="regular-12 no-margin">{{ o.failureReason }}</p>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="invoice">
                            <th mat-header-cell *matHeaderCellDef>{{ 'com_up_fees_invoice' | translate }}</th>
                            <td mat-cell *matCellDef="let o"  style="padding-left: 20px;">
                                <a *ngIf="o.rmInvoice" href="{{o.rmInvoice.metadata.url}}" target="_blank">
                                    <img src="../../../assets/img/cc_payment_invoice.svg" />
                                </a>
                            </td>
                        </ng-container>
    
                        <tr mat-header-row *matHeaderRowDef="displayedColumnsOperations"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumnsOperations"
                        ></tr>
                    </table>
                </mat-card>
            </div>

            <div *ngIf="mobile &&operations.length > 0" fxLayout="column" fxLayoutAlign="flex-start flex-start" style="width: 100%; margin: 30px 0 40px 0;">

                <p class="semi-bold-15 no-margin">{{ 'com_payment_operations' | translate }}</p>
                <div class="sep-row-light"></div>

                <ng-container *ngFor="let o of operations">
                    <app-mobile-payment-operation-card [operation]="o" (on3DSLinkPressed)="on3DSLinkPressed(o)"></app-mobile-payment-operation-card>
                </ng-container>
            </div>
        </div>
    </div>
</div>