import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SnotifyService } from 'ng-snotify';


import { combineLatest, firstValueFrom, Observable, of, ReplaySubject, Subject } from 'rxjs';
import { catchError, filter, map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import {
    BankData,
    BrowserInfo,
    InvoiceData,
    LandlordTenantData,
    NotificationCounters,
    UserData
} from '../models/common';
import { AttachedFile, PendingFile } from '../models/fileRm';
import { ChatMessage, QueryResult } from '../models/message';
import { CardRegistrationReturnObject, EpaymentProcedureBody, PaymentDetails, PaymentMethods, PaymentOperation, PropertyPayment, RecurringPayment, UserPaymentInfo, UserPaymentInfoBankAccountData, UserPaymentInfoProfileData } from '../models/payments';
import { MaintenanceRequest } from '../models/properties';
import { AppUtils } from '../utils/app-utils';
import { LocalizationUtils } from '../utils/localization-utils';
import { AuthService } from './auth.service';
import { LoadingService } from './loading.service';


@Injectable({
  providedIn: 'root',
})
export class BackendService {
    BACKEND_HOST = `${environment.services.backend}`;
    BACKEND_PAYMENTS_HOST = `${environment.services.backendPayments}`;
    BACKEND_PAYMENTS_MANDATE_CREATION_REDIRECT_URL = `${environment.mangopay.mandateCreationRedirectUrl}`;
    BACKEND_FILES_HOST = `${environment.services.fileManager}`;
    BACKEND_EVENTS_HOST = `${environment.services.backendEvents}`

    userId$: Observable<string>;

    tenantId$: Subject<string> = new ReplaySubject<string>(1);
    landlordId$: Subject<string> = new ReplaySubject<string>(1);

    propertyId$: Subject<string> = new ReplaySubject<string>(1);
    landlordName$: Subject<string> = new ReplaySubject<string>(1);


    private userDataCache : Observable<UserData> | undefined;
    private tenantLandlordDataCache : Observable<LandlordTenantData> | undefined;
    private maintenancesCache : Observable<MaintenanceRequest[]> | undefined;
    private paymentsCache : Observable<PropertyPayment[]> | undefined;

    public homepagePaymentsSetupReminderSeen : boolean = false;


    constructor(
        private readonly router: Router,
        private readonly http: HttpClient,
        private readonly authService: AuthService,
        private readonly loadingService: LoadingService,
        private readonly appUtils: AppUtils
    ) {
        this.userId$ = this.authService.getUserId();
    }

    public clearCache () {
        this.userDataCache = undefined;
        this.tenantLandlordDataCache = undefined;
        this.maintenancesCache = undefined;
        this.paymentsCache = undefined;
        
        this.homepagePaymentsSetupReminderSeen = false
    }

	loadBasicUserData(): Observable<UserData> {

        if (!!!this.userDataCache) {

            setTimeout(() => {
                this.userDataCache = undefined;
            }, 60000);
            
            this.userDataCache = this.userId$.pipe(
                switchMap((userId) =>
                    this.http.get<UserData>(`${this.BACKEND_HOST}/${userId}`, {
                        observe: 'response',
                        responseType: 'json'
                    }).pipe(
                        catchError(err => of(null)),
                        map(response => {
                            if (response) {
                                console.log(`Connected to backend ${response.headers.get('roommate-backend-version') || 'unkown'} `);
                                return response.body;
                            } else {
                                return null;
                            }
                        })
                    )
                ),
                tap((data) => {
                    if (data) {
                        const currentLandlord = data.landlords.find(
                            (ll) => ll.id === data.currentLandlordId
                        );
        
                        if (currentLandlord) {
                            this.tenantId$.next(currentLandlord.tenantId);
                            this.landlordId$.next(currentLandlord.id);
                        }
                    }
                }),
                shareReplay(1),
                tap(it => {
                    if (!it) {
                        this.loadingService.hide();
                        this.router.navigate(['/error'], { state: { errorType: 'user' } })
                    }
                }),
                filter((it: any) => !!it)
            );
        }

        return this.userDataCache;
	}

    editBasicUserData(
        user: UserData
    ): Promise<UserData> {
        return firstValueFrom(this.userId$.pipe(
            switchMap((userId) =>
                this.http.put<UserData>(
                    `${this.BACKEND_HOST}/${userId}`,
                    user
                )
            ),
            tap(() => {
                this.userDataCache = undefined;
            })
        ));
    }

    updateInvoiceData(
        invoiceData: InvoiceData
    ): Promise<UserData>{
        return firstValueFrom(this.loadBasicUserData().pipe(switchMap(userData => {
            userData.invoiceData = invoiceData;
            return this.editBasicUserData(userData);
        })));
    }

    loadTenantLandlordData(): Observable<LandlordTenantData> {

        if (!!!this.tenantLandlordDataCache) {

            setTimeout(() => {
                this.tenantLandlordDataCache = undefined;
            }, 60000);

            this.tenantLandlordDataCache = this.userId$.pipe(
                switchMap((userId) =>
                    combineLatest([of(userId), this.tenantId$, this.landlordId$])
                ),
                switchMap(([userId, tenantId, landlordId]) =>
                    this.http.get<LandlordTenantData>(
                        `${this.BACKEND_HOST}/${userId}/landlords/${landlordId}/tenants/${tenantId}`
                    ).pipe(
                        catchError(err => of(null))
                    )
                ),
                tap((data) => {
                    if (data) {
                        this.landlordName$.next(data.name);
                        if (data.currentProperty) {
                            this.propertyId$.next(data.currentProperty.id);
                        }
                    }
                }),
                shareReplay(1),
                tap(it => {
                    if (!it) {
                        this.loadingService.hide();
                        this.router.navigate(['/error'], { state: { errorType: 'tenant' } })
                    }
                }),
                filter((it: any) => !!it)
            );
        }

        return this.tenantLandlordDataCache;
    }

    editTenantLandlordBankData(
        bankData: BankData
    ): Promise<Object> {

        return firstValueFrom(this.userId$.pipe(
            switchMap((userId) =>
                combineLatest([of(userId), this.tenantId$, this.landlordId$])
            ),
            switchMap(([userId, tenantId, landlordId]) =>
                this.http.put<Object>(
                    `${this.BACKEND_HOST}/${userId}/landlords/${landlordId}/tenants/${tenantId}/bankData`,
                    {
                        "bankData": bankData
                    }
                )
            ),
            tap(() => {
                this.tenantLandlordDataCache = undefined;
            })
        ));
    }

    getMaintenances(): Observable<MaintenanceRequest[]> {

        if (!!!this.maintenancesCache) {

            setTimeout(() => {
                this.tenantLandlordDataCache = undefined;
            }, 60000);

            this.maintenancesCache = this.userId$.pipe(
                switchMap((userId) =>
                    combineLatest([of(userId), this.tenantId$, this.landlordId$])
                ),
                switchMap(([userId, tenantId, landlordId]) =>
                    this.http.get<MaintenanceRequest[]>(
                        `${this.BACKEND_HOST}/${userId}/landlords/${landlordId}/tenants/${tenantId}/maintenances`
                    )
                ),
                shareReplay(1)
            );
        }

        return this.maintenancesCache;
    }

    getMaintenance(maintenanceId: string): Observable<MaintenanceRequest | undefined> {
        return this.getMaintenances().pipe(map(it => it.find(maintenance => maintenance.id === maintenanceId)));
    }

    addMaintenanceRequest(
        maintenanceRequest: MaintenanceRequest
    ): Promise<MaintenanceRequest> {
        return firstValueFrom(this.userId$.pipe(
            switchMap((userId) =>
                combineLatest([of(userId), this.tenantId$, this.landlordId$])
            ),
            switchMap(([userId, tenantId, landlordId]) =>
                this.http.post<MaintenanceRequest>(
                    `${this.BACKEND_HOST}/${userId}/landlords/${landlordId}/tenants/${tenantId}/maintenances`,
                    {
                        "maintenance": maintenanceRequest
                    }
                )
            ),
            tap(() => {
                this.maintenancesCache = undefined;
            })
        ));
    }

  /*
  editMaintenanceRequest(
    maintenanceRequest: MaintenanceRequest
  ): Observable<MaintenanceRequest> {
    return this.userId$.pipe(
      switchMap((userId) =>
        combineLatest([of(userId), this.tenantId$, this.landlordId$])
      ),
      switchMap(([userId, tenantId, landlordId]) =>
        this.http.put<MaintenanceRequest>(
          `${this.BACKEND_HOST}/${userId}/landlords/${landlordId}/tenants/${tenantId}/maintenances/${maintenanceRequest.id}`,
          maintenanceRequest
        )
      )
    );
  }
  */

    deleteMainteanceRequest(maintenanceId: string): Promise<Object> {
        return firstValueFrom(this.userId$.pipe(
            switchMap((userId) =>
                combineLatest([of(userId), this.tenantId$, this.landlordId$])
            ),
            switchMap(([userId, tenantId, landlordId]) =>
                this.http.delete(
                    `${this.BACKEND_HOST}/${userId}/landlords/${landlordId}/tenants/${tenantId}/maintenances/${maintenanceId}`
                )
            ),
            tap(() => {
                this.maintenancesCache = undefined;
            })
        ));
    }

    getCurrentCounters(): Observable<NotificationCounters> {
        return this.userId$.pipe(
            switchMap((userId) =>
                combineLatest([of(userId), this.tenantId$, this.landlordId$])
            ),
            switchMap(([userId, tenantId, landlordId]) =>
                this.http.get<NotificationCounters>(
                    `${this.BACKEND_HOST}/${userId}/landlords/${landlordId}/tenants/${tenantId}/counters`
                )
            )
        );
    }

	updateCounter(
		counterId: string,
		value: number
	): Observable<NotificationCounters> {
		return this.userId$.pipe(
			switchMap((userId) =>
				combineLatest([of(userId), this.tenantId$, this.landlordId$])
			),
			switchMap(([userId, tenantId, landlordId]) =>
				this.http.put<NotificationCounters>(
					`${this.BACKEND_HOST}/${userId}/landlords/${landlordId}/tenants/${tenantId}/counters/${counterId}`,
					{
						[counterId]: value,
					}
				)
			)
		);
	}

	resetCounter(counterId: string): Promise<NotificationCounters> {
		return firstValueFrom(this.userId$.pipe(
			switchMap((userId) =>
				combineLatest([of(userId), this.tenantId$, this.landlordId$])
			),
			switchMap(([userId, tenantId, landlordId]) =>
				this.http.delete<NotificationCounters>(
					`${this.BACKEND_HOST}/${userId}/landlords/${landlordId}/tenants/${tenantId}/counters/${counterId}`
				)
			)
		));
	}

	contactUs(title: string, desc: string) : Promise<Object> {

        return firstValueFrom(this.userId$.pipe(
            switchMap((userId) =>
                this.http.post<{'title': string, 'message': string}>(
                    `${this.BACKEND_HOST}/${userId}/contactRequest`,
                    {
						'title': title,
						'message': desc
					}
                )
            )
        ));
    }

	
    // Payments setup

    getUserPaymentInfo(): Observable<UserPaymentInfo> {
		return this.userId$.pipe(
			switchMap((userId) =>
				combineLatest([of(userId), this.tenantId$, this.landlordId$])
			),
			switchMap(([userId, tenantId, landlordId]) =>
				this.http.get<UserPaymentInfo>(
					`${this.BACKEND_PAYMENTS_HOST}/users/${userId}`
				)
			)
		);
	}

    postPaymentsProfile(data: UserPaymentInfoProfileData): Promise<UserPaymentInfo> {
		
		return firstValueFrom(this.userId$.pipe(
            switchMap((userId) =>
				this.http.post<UserPaymentInfo>(`${this.BACKEND_PAYMENTS_HOST}/users/${userId}/profile`, data)
            )
        ));
	}

    postPaymentsBankAccount(data: UserPaymentInfoBankAccountData): Promise<UserPaymentInfo> {
		
		return firstValueFrom(this.userId$.pipe(
            switchMap((userId) =>
				this.http.post<UserPaymentInfo>(`${this.BACKEND_PAYMENTS_HOST}/users/${userId}/bankAccount`, data)
            )
        ));
	}

    postPaymentsKYCFiles(fileFront: File, fileBack?: File): Promise<UserPaymentInfo> {
		
        var formData: any = new FormData();
        formData.append('files', fileFront, 'front');

        if (fileBack) {
            formData.append('files', fileBack, 'back');
        }
        
        
		return firstValueFrom(this.userId$.pipe(
            switchMap((userId) => 
                this.http.post<UserPaymentInfo>(`${this.BACKEND_PAYMENTS_HOST}/users/${userId}/kyc`, formData)
            )
        ));
	}
    
    setToken(token: string, deviceOs: string) {
        
        if (!this.authService.isGodUser()) {

            firstValueFrom(this.userId$.pipe(
                switchMap((userId) => 
                    this.http.post<UserPaymentInfo>(`${this.BACKEND_HOST}/${userId}/tokens`, {
                        appVersion: 'dev',
                        deviceOs: deviceOs,
                        language: LocalizationUtils.getLanguage(),
                        token,
                        creationTime: Date.now()
                    }
                    )
                )
            ));
        }
	}



    // Paymentas view

    getPayments(): Observable<PropertyPayment[]> {

        if (!!!this.paymentsCache) {

            setTimeout(() => {
                this.paymentsCache = undefined;
            }, 60000);

            this.paymentsCache = this.userId$.pipe(
                switchMap((userId) =>
                    combineLatest([of(userId), this.tenantId$, this.landlordId$])
                ),
                switchMap(([userId, tenantId, landlordId]) =>
                    this.http.get<PropertyPayment[]>(
                        `${this.BACKEND_HOST}/${userId}/landlords/${landlordId}/tenants/${tenantId}/payments`
                    )
                ),
                shareReplay(1)
            );
        }

        return this.paymentsCache;
	}

    getPayment(paymentId: string): Observable<PropertyPayment | undefined> {
		return this.getPayments().pipe(map(it => it.find(payment => payment.id === paymentId)));
	}

	getPaymentOperations(paymentId: string): Observable<PaymentOperation[]> {
		return this.userId$.pipe(
			switchMap((userId) =>
				combineLatest([of(userId), this.tenantId$, this.landlordId$])
			),
			switchMap(([userId, tenantId, landlordId]) =>
				this.http.get<PaymentOperation[]>(
					`${this.BACKEND_HOST}/${userId}/landlords/${landlordId}/tenants/${tenantId}/paymentOperations/${paymentId}`
				)
			)
		);
	}
    
  
    // Payments pay

    postPaymentsMandateCreation(fromPaymentId: string, amount: number): Promise<UserPaymentInfo> {
		
        return firstValueFrom(this.userId$.pipe(
            switchMap((userId) => 
                this.http.post<UserPaymentInfo>(`${this.BACKEND_PAYMENTS_HOST}/users/${userId}/mandate`, {
                    "urlAfterComplete": `${this.BACKEND_PAYMENTS_MANDATE_CREATION_REDIRECT_URL}/mandateCreationSucceeded?paymentId=${fromPaymentId}&amount=${amount}&mandateStatus=submitted`
                })
            )
        ));
	}

    postPaymentsCreditCardStartProcedure(cardType: string): Promise<CardRegistrationReturnObject> {
        return firstValueFrom(this.userId$.pipe(
            switchMap((userId) => 
                this.http.post<CardRegistrationReturnObject>(`${this.BACKEND_PAYMENTS_HOST}/users/${userId}/creditCardStart`, {
                    cardType
                })
            )
        ));
    }

    postPaymentsCreditCardFinalizeProcedure(paymentId: string, amount: number, paymentMethod: PaymentMethods, paymentMethodId: string, dataToken: string, cardRegistrationId: string, saveCCInfo: boolean, historyLength: number, idempotencyKey: string, recurring: boolean): Promise<{payment: PropertyPayment, paymentOperation: PaymentOperation, secureModeRedirectUrl: string }> {
        this.paymentsCache = undefined;
        
        return firstValueFrom(this.userId$.pipe(
            switchMap((userId) =>
				combineLatest([of(userId), this.landlordId$])
			),
			switchMap(([userId, landlordId]) => {

                const epaymentProcedureBody: EpaymentProcedureBody = {
                    paymentId: paymentId,
                    landlordId: landlordId,
                    amount: amount,
                    paymentMethodId: paymentMethodId,
                    paymentMethod: paymentMethod,
                    saveCCInfo: saveCCInfo,
                    browserInfo: this.appUtils.getBrowserInfo(),
                    historyLength,
                    idempotencyKey,
                    recurringPayment: recurring
                }

                return this.http.post<{payment: PropertyPayment, paymentOperation: PaymentOperation, secureModeRedirectUrl: string}>(`${this.BACKEND_PAYMENTS_HOST}/users/${userId}/creditCardFinalize`, {
                    dataToken,
                    cardRegistrationId,
                    epaymentProcedureBody
                })
            })
        ));
    }

    handleSecureModeRedirect(payinId: string): Promise<{payment: PropertyPayment, paymentOperation: PaymentOperation }> {
        
        return firstValueFrom(this.userId$.pipe(
            switchMap((userId) =>
				combineLatest([of(userId), this.landlordId$])
			),
			switchMap(([userId, landlordId]) => {

                return this.http.post<{payment: PropertyPayment, paymentOperation: PaymentOperation}>(`${this.BACKEND_PAYMENTS_HOST}/creditCardSecureModeCallBack`, {
                    payinId
                })
            })
        ));
    }

    deletePaymentMethod(paymentMethodId: string){
        return firstValueFrom(this.userId$.pipe(
            switchMap((userId) =>
				combineLatest([of(userId), this.landlordId$])
			),
			switchMap(([userId, landlordId]) => {
                return this.http.delete<void>(`${this.BACKEND_PAYMENTS_HOST}/users/${userId}/creditCardDeactivate/${paymentMethodId}`)
            })
    ))}
    
    cancelRecurringPayment(recurringPaymentId: string){
        return firstValueFrom(this.userId$.pipe(
            switchMap((userId) =>
				combineLatest([of(userId), this.landlordId$])
			),
			switchMap(([userId, landlordId]) => {
                return this.http.delete<void>(`${this.BACKEND_PAYMENTS_HOST}/users/${userId}/cancelRecurringPayment/${recurringPaymentId}`)
            })
    ))}

    postPayment(paymentId: string, amount: number, paymentMethod: PaymentMethods, paymentMethodId: string, historyLength: number | undefined, idempotencyKey: string, recurring?: boolean): Promise<{"payment": PropertyPayment, "paymentOperation": PaymentOperation, secureModeRedirectUrl?: string}> {
        this.paymentsCache = undefined;
        
        return firstValueFrom(this.userId$.pipe(
            switchMap((userId) =>
				combineLatest([of(userId), this.landlordId$])
			),
			switchMap(([userId, landlordId]) => {

                const epaymentProcedureBody: EpaymentProcedureBody = {
                    paymentId: paymentId,
                    landlordId: landlordId,
                    amount: amount,
                    paymentMethodId: paymentMethodId,
                    paymentMethod: paymentMethod,
                    browserInfo: this.appUtils.getBrowserInfo(),
                    historyLength,
                    idempotencyKey,
                    recurringPayment: recurring
                }

                return this.http.post<{"payment": PropertyPayment, "paymentOperation": PaymentOperation, secureModeRedirectUrl?: string}>(`${this.BACKEND_PAYMENTS_HOST}/users/${userId}/paymentOperations`, epaymentProcedureBody)
            })
        ));
	}

    postPaymentDetails(paymentId: string, amount: number): Promise<PaymentDetails> {
        this.paymentsCache = undefined;
        
            return firstValueFrom(this.userId$.pipe(
                switchMap((userId) =>
                    combineLatest([of(userId), this.landlordId$])
                ),
                switchMap(([userId, landlordId]) => {

                    const paymentDetailsBody = {
                        landlordId: landlordId,
                        amountToPay: amount,
                        paymentId: paymentId
                    }

                    // return of({
                    //     feesAmount: 1220,
                    //     netFeeAmount: 1000,
                    //     totalAmount: 51220,
                    //     vatRate: 24,
                    //     feesType: 'tenant'
                    // } as PaymentDetails)
                    
                    return this.http.post<PaymentDetails>(`${this.BACKEND_PAYMENTS_HOST}/users/${userId}/paymentOperations/fees`, paymentDetailsBody)
                })
            ));
	}

    // Chat

    public getAllMessagesPaginated(page: number): Observable<QueryResult<ChatMessage>> {

        return this.userId$.pipe(
			switchMap((userId) =>
				combineLatest([of(userId), this.tenantId$, this.landlordId$])
			),
			switchMap(([userId, tenantId, landlordId]) =>
				this.http.get<QueryResult<ChatMessage>>(
					`${this.BACKEND_HOST}/${userId}/landlords/${landlordId}/tenants/${tenantId}/chatMessages`,
                    {
                        params: {
                            page: page
                        }
                    }
				)
			)
		);
	}

    public getAllCommunicationsPaginated(page: number): Observable<QueryResult<ChatMessage>> {

        return this.userId$.pipe(
			switchMap((userId) =>
				combineLatest([of(userId), this.tenantId$, this.landlordId$])
			),
			switchMap(([userId, tenantId, landlordId]) =>
				this.http.get<QueryResult<ChatMessage>>(
					`${this.BACKEND_HOST}/${userId}/landlords/${landlordId}/tenants/${tenantId}/landlordCommunications`,
                    {
                        params: {
                            page: page
                        }
                    }
				)
			)
		);
	}


    public writeChatMessage(
		text: string,
        unitId: string,
		attachment: string = ''
	): Promise<Object> {
		
		return firstValueFrom(this.userId$.pipe(
            switchMap((userId) =>
				combineLatest([of(userId), this.landlordId$, this.tenantId$])
			),
			switchMap(([userId, landlordId, tenantId]) =>
                this.http.post<any>(
                    `${this.BACKEND_HOST}/${userId}/landlords/${landlordId}/tenants/${tenantId}/chatMessages`,
                    {
						'message': text,
                        'unitId': unitId || undefined,
						'url': attachment
					}
                )
            )
        ));
	}

    public async loadFiles(entityType: string, entityId: string): Promise<AttachedFile[]> {
        try {
            return await this.loadFilesCall(entityType, entityId);
        } catch (e) {
            console.log(`FILES ERROR ${entityType} ${entityId}`)
        }

        return [];
    }

    public getRecurringPayment(recurringPaymentId: string): Observable<RecurringPayment> {
        return this.userId$.pipe(
			switchMap((userId) =>
				combineLatest([of(userId), this.tenantId$, this.landlordId$])
			),
			switchMap(([userId, tenantId, landlordId]) =>
				this.http.get<RecurringPayment>(
					`${this.BACKEND_HOST}/${userId}/landlords/${landlordId}/tenants/${tenantId}/recurringPayments/${recurringPaymentId}`,
			)
		));
    }

    private async loadFilesCall(entityType: string, entityId: string) : Promise<AttachedFile[]> {
        return firstValueFrom(this.userId$.pipe(
			switchMap((userId) =>
				combineLatest([of(userId), this.tenantId$, this.landlordId$])
			),
			switchMap(([userId, tenantId, landlordId]) =>
				this.http.get<AttachedFile[]>(`${this.BACKEND_FILES_HOST}/tenants/${landlordId}/${tenantId}/files/attachedFile/${entityType}/${entityId}`)
			)
		));
    } 

    public async uploadFile(fileToShare: PendingFile): Promise<AttachedFile> {
        try{
            return await this.uploadFileCall(fileToShare.file);
        } catch(err) {
            console.log(`There was an error while uploading a file `)
        }

        return {} as AttachedFile;
    }

    private async uploadFileCall(file?: File): Promise<AttachedFile> {
        let formData: FormData = new FormData();
        if(file){
            formData.append('file', file );
        }
        let headers = new HttpHeaders();
        headers.append('Content-Type', 'multipart/form-data');

        return firstValueFrom(this.userId$.pipe(
			switchMap((userId) =>
				combineLatest([of(userId), this.tenantId$, this.landlordId$])
			),
			switchMap(([userId, tenantId, landlordId]) =>{
            console.log(tenantId, landlordId)
                return this.http.post<AttachedFile>(`${this.BACKEND_FILES_HOST}/tenants/${landlordId}/${tenantId}/files/tenantupload/${tenantId}?public=true`,
                    formData, { headers })}
			)
		));
    }
}
