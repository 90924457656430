import { AgmCoreModule } from '@agm/core';
import { MatGoogleMapsAutocompleteModule } from '@angular-material-extensions/google-maps-autocomplete';
import { CurrencyPipe, LOCATION_INITIALIZED, registerLocaleData } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, Injector, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatCheckboxModule } from "@angular/material/checkbox"
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSelectModule } from '@angular/material/select';
import {MatTooltipModule} from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgImageSliderModule } from 'ng-image-slider';
import { DateFnsConfigurationService, DateFnsModule } from 'ngx-date-fns';
import { NgxLinkifyjsModule } from 'ngx-linkifyjs';
import { ScrollEventModule } from 'ngx-scroll-event';
import { NgxSpinnerModule } from 'ngx-spinner';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ChatComponent } from './chat/chat.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { HomepageComponent } from './homepage/homepage.component';
import { DropDownList } from "./homepage/dropdown-list/dropdown-list.component";
import { LeaseDataComponent } from './lease-data/lease-data.component';
import { LoginComponent } from './login/login.component';
import { MaintenanceAddComponent } from './maintenances/maintenance-add/maintenance-add.component';
import { MaintenanceComponent } from './maintenances/maintenance/maintenance.component';
import { MaintenancesComponent } from './maintenances/maintenances.component';
import { PaymentComponent } from './payments/payment/payment.component';
import { PaymentsComponent } from './payments/payments.component';
import { BackendService } from './services/backend.service';
import { InterceptorService } from './services/interceptor/interceptor.service';
import { SettingsComponent } from './settings/settings.component';
import { AddressFormComponent } from './shared/address-form/address-form.component';
import { BankFormComponent } from './shared/bank-form/bank-form.component';
import { DashLabelSimpleComponent } from './shared/dash-label-simple/dash-label-simple.component';
import { FilePickerComponent } from './shared/file-picker/file-picker.component';
import { InvoiceFormComponent } from './shared/invoice-form/invoice-form.component';
import { RoommateTabsComponent } from './shared/roommate-tabs/roommate-tabs.component';
import { TagListComponent } from './shared/tag-list/tag-list.component';
import { UnitInfoComponent } from './unit-info/unit-info.component';
import { MatSelectCountryModule, MatSelectCountrySupportedLanguages } from '@angular-material-extensions/select-country';
import localeIt from '@angular/common/locales/it';
import localeEs from '@angular/common/locales/es';
import localeFr from '@angular/common/locales/fr';
import localeEn from '@angular/common/locales/en';
import { it, enUS, fr, es } from 'date-fns/locale';
import { MatNativeDateModule } from '@angular/material/core';
import { DragDropDirective } from './directives/drag-drop-directive';
import { MobilePaymentCardComponent } from './shared/mobile-payment-card/mobile-payment-card.component';
import { MobileMaintenanceCardComponent } from './shared/mobile-maintenance-card/mobile-maintenance-card.component';
import { MobilePaymentOperationCardComponent } from './shared/mobile-payment-operation-card/mobile-payment-operation-card.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { ServiceWorkerModule } from '@angular/service-worker';
import { SnotifyModule, SnotifyService, ToastDefaults } from 'ng-snotify';
import { NgxPicaModule } from '@digitalascetic/ngx-pica';
import { CropPictureDialogComponent } from './shared/crop-picture-dialog/crop-picture-dialog.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { RequestDataComponent } from './settings/request-data/request-data.component';
import { PasswordlessLoginComponent } from './password/passwordless-login/passwordless-login.component';
import { ChoosePasswordComponent } from './password/choose-password/choose-password.component';
import { ProcessPaymentAfterMandateCreationComponent } from './payments/process-payment-after-mandate-creation/process-payment-after-mandate-creation.component';
import { CookieService } from 'ngx-cookie-service';
import { FilesComponent } from './files/files.component';
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';
import { LoadingComponent } from './loading/loading.component';
import { MessagingService } from './services/messaging.service';
import { initializeApp } from 'firebase/app';
import { DeleteConfirmDialogComponent } from './shared/delete-confirm-dialog/delete-confirm-dialog.component';
import { CropPicturePageComponent } from './shared/crop-picture-page/crop-picture-page.component';
import { PrivateChatComponent } from './chat/private-chat/private-chat.component';
import { UnitSidebarComponent } from './chat/unit-sidebar/unit-sidebar.component';
import { CommunicationsChatComponent } from './chat/communications-chat/communications-chat.component';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';

// Translations

import { HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';


// Capacitor plugins

import { registerPlugin } from '@capacitor/core';
import { UserNotLinkedComponent } from './homepage/user-not-linked/user-not-linked.component';
import { PasswordRecoveryComponent } from './password/password-recovery/password-recovery.component';
import { PaymentsSetupReminderDialogComponent } from './payments-setup-reminder-dialog/payments-setup-reminder-dialog.component';
import { PaymentCompleteConfirmDialogComponent } from './payment-complete-confirm-dialog/payment-complete-confirm-dialog.component';
import { ErrorPageComponent } from './error-page/error-page.component';
import { PaymentsSettingsComponent } from './payments-settings/payments-settings.component';
import { AppUtils } from './utils/app-utils';
import { ConfirmPaymentNoticeDialogComponent } from './confirm-payment-notice-dialog/confirm-payment-notice-dialog.component';
import { InvoiceDataAddDialogComponent } from './invoice-data-add-dialog/invoice-data-add-dialog.component';




// Loading language on angular

let preferredLanguage = 'en';

const dateLanguageConfig = new DateFnsConfigurationService();

registerLocaleData(localeEn, 'en');
preferredLanguage = 'en';
dateLanguageConfig.setLocale(enUS);

if (localStorage.getItem('locale') === 'it') {
	registerLocaleData(localeIt, 'it');
	preferredLanguage = 'it';
	dateLanguageConfig.setLocale(it);
} else if (localStorage.getItem('locale') === 'es') {
	registerLocaleData(localeEs, 'es');
	preferredLanguage = 'es';
	dateLanguageConfig.setLocale(es);
} else if (localStorage.getItem('locale') === 'fr') {
	registerLocaleData(localeFr, 'fr');
	preferredLanguage = 'fr';
	dateLanguageConfig.setLocale(fr);
} else if (localStorage.getItem('locale') === 'en') {
	registerLocaleData(localeEn, 'en');
	preferredLanguage = 'en';
    dateLanguageConfig.setLocale(enUS);
} else {

    // Language not set yet => choose the right one from the navigator

    const userLang = navigator.language.substr(0, 2);

    if (userLang === 'it') {
        registerLocaleData(localeIt, 'it');
        preferredLanguage = 'it';
        dateLanguageConfig.setLocale(it);
    } else if (userLang === 'es') {
        registerLocaleData(localeEs, 'es');
        preferredLanguage = 'es';
        dateLanguageConfig.setLocale(es);
    } else if (userLang === 'fr') {
        registerLocaleData(localeFr, 'fr');
        preferredLanguage = 'fr';
        dateLanguageConfig.setLocale(fr);
    } else {
        registerLocaleData(localeEn, 'en');
        preferredLanguage = 'en';
        dateLanguageConfig.setLocale(enUS);
    }
}




// Setup translations

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}

export function appInitializerFactory(translate: TranslateService, injector: Injector) {
    
    return () => new Promise<any>((resolve: any) => {

        const locationInitialized = injector.get(LOCATION_INITIALIZED, Promise.resolve(null));
        
        locationInitialized.then(() => {
            
            translate.setDefaultLang('en');

            translate.use(preferredLanguage).subscribe(() => {
                resolve(null);
            });
        });
    });
}



// Setup capacitor

export interface RoomMatePlugin {
    setupBackNavigation(options: { webviewSwipeBack: boolean }): Promise<{ status: boolean }>;
}

export const RoomMate = registerPlugin<RoomMatePlugin>('RoomMate');


// Setup Google Maps

const googleMapsParams = {
	apiKey: environment.maps.key,
	libraries: ['places'],
	language: 'en'
	// region: 'DE'
};


// Setup Firebase

export const firebaseApp = initializeApp(environment.firebase);



const socketConfig: SocketIoConfig = {
	url: environment.services.socket,
	options: {
		transports: ['websocket']
	}
};


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DashboardComponent,
    HomepageComponent,
    DropDownList,
    SettingsComponent,
    LeaseDataComponent,
    UnitInfoComponent,
    PaymentsComponent,
    PaymentComponent,
    MaintenancesComponent,
    MaintenanceComponent,
    MaintenanceAddComponent,
    ChatComponent,
    RoommateTabsComponent,
    InvoiceFormComponent,
    BankFormComponent,
    DashLabelSimpleComponent,
    TagListComponent,
    AddressFormComponent,
    FilePickerComponent,
    DragDropDirective,
    MobilePaymentCardComponent,
    MobileMaintenanceCardComponent,
    MobilePaymentOperationCardComponent,
    ContactUsComponent,
    CropPictureDialogComponent,
    RequestDataComponent,
    PasswordlessLoginComponent,
    ChoosePasswordComponent,
    ProcessPaymentAfterMandateCreationComponent,
    FilesComponent,
    LoadingComponent,
    DeleteConfirmDialogComponent,
    CropPicturePageComponent,
    PrivateChatComponent,
    UnitSidebarComponent,
    CommunicationsChatComponent,
    UserNotLinkedComponent,
    PasswordRecoveryComponent,
    PaymentsSetupReminderDialogComponent,
    PaymentCompleteConfirmDialogComponent,
    ConfirmPaymentNoticeDialogComponent,
    ErrorPageComponent,
    PaymentsSettingsComponent,
    InvoiceDataAddDialogComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    NgxSpinnerModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatTabsModule,
    MatRadioModule,
    MatTableModule,
    MatCardModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSelectModule,
    MatCheckboxModule,
    MatMenuModule,
    MatIconModule,
    MatDialogModule,
    MatTooltipModule,
    FlexLayoutModule,
    ImageCropperModule,
    NgImageSliderModule,
    AgmCoreModule.forRoot(googleMapsParams),
	MatGoogleMapsAutocompleteModule,
    DateFnsModule.forRoot(),
    NgxLinkifyjsModule.forRoot(),
    ScrollEventModule,
    HttpClientModule,
    SnotifyModule,
    MatSelectCountryModule.forRoot(preferredLanguage as MatSelectCountrySupportedLanguages),
    // https://meumobi.github.io/ionic/firebase/2020/01/20/firebase-web-push-ionic.html
		// This should allow us to have both the angular service worker and the angularfire one
		ServiceWorkerModule.register('combined-sw.js', { enabled: environment.production }),
    NgxPicaModule,
    NgxGoogleAnalyticsModule.forRoot('G-HV1CNKWQS8'),
    NgxGoogleAnalyticsRouterModule,
    TranslateModule.forRoot({
        loader: {
            provide: TranslateLoader,
            useFactory: (createTranslateLoader),
            deps: [HttpClient]
        }
    }),
    SocketIoModule.forRoot(socketConfig)
  ],
  providers: [
        CurrencyPipe,
        BackendService,
        { provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true },
        { provide: DateFnsConfigurationService, useValue: dateLanguageConfig },
        MessagingService,
        SnotifyService,
        { provide: 'SnotifyToastConfig', useValue: ToastDefaults },
        CookieService,
        {
            provide: APP_INITIALIZER,
            useFactory: appInitializerFactory,
            deps: [TranslateService, Injector],
            multi: true
        },
        AppUtils
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/strings/', '.json?v=' + Date.now());
}