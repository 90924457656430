import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ChatComponent } from './chat/chat.component';
import { ChoosePasswordComponent } from './password/choose-password/choose-password.component';
//import { ContactUsComponent } from './contact-us/contact-us.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { HomepageComponent } from './homepage/homepage.component';
import { LeaseDataComponent } from './lease-data/lease-data.component';
import { LoginComponent } from './login/login.component';
import { MaintenanceAddComponent } from './maintenances/maintenance-add/maintenance-add.component';
import { MaintenanceComponent } from './maintenances/maintenance/maintenance.component';
import { MaintenancesComponent } from './maintenances/maintenances.component';
import { PasswordlessLoginComponent } from './password/passwordless-login/passwordless-login.component';
import { PaymentComponent } from './payments/payment/payment.component';
import { PaymentsComponent } from './payments/payments.component';
import { AuthGuardService } from './services/auth-guard.service';
import { RequestDataComponent } from './settings/request-data/request-data.component';
import { SettingsComponent } from './settings/settings.component';
import { UnitInfoComponent } from './unit-info/unit-info.component';
import { ProcessPaymentAfterMandateCreationComponent } from './payments/process-payment-after-mandate-creation/process-payment-after-mandate-creation.component';
import { FilesComponent } from './files/files.component';
import { LoadingComponent } from './loading/loading.component';
import { CropPicturePageComponent } from './shared/crop-picture-page/crop-picture-page.component';
import { UserNotLinkedComponent } from './homepage/user-not-linked/user-not-linked.component';
import { PasswordRecoveryComponent } from './password/password-recovery/password-recovery.component';
import { ErrorPageComponent } from './error-page/error-page.component';
import { PaymentsSettingsComponent } from './payments-settings/payments-settings.component';

const routes: Routes = [
	{
		path: 'login',
		component: LoginComponent
	},
    {
		path: 'passwordrecovery',
		component: PasswordRecoveryComponent
	},
    {
		path: 'loading',
		component: LoadingComponent
	},
    {
		path: 'passwordlessLogin',
		component: PasswordlessLoginComponent
	},

	{
		path: '',
        component: DashboardComponent,
		canActivate: [AuthGuardService],
        children: [
			{
				path: '',
				component: HomepageComponent,
                data: { animation: 'HomePage' }
			},
            {
				path: 'missingLandlordInfo',
				component: UserNotLinkedComponent
			},
            {
				path: 'choosepassword',
				component: ChoosePasswordComponent,
                data: { animation: 'SlidePage' }
			},
			{
				path: 'settings',
				component: SettingsComponent,
                data: { animation: 'SlidePage' }
			},
            {
				path: 'personalData/request',
				component: RequestDataComponent
			},
            {
				path: 'personalData/delete',
				component: RequestDataComponent
			},
            {
                path: 'chooseProfilePicture',
                component: CropPicturePageComponent
            },
			{
				path: 'lease',
				component: LeaseDataComponent,
                data: { animation: 'SlidePage' }
			},
			{
				path: 'unit',
				component: UnitInfoComponent,
                data: { animation: 'SlidePage' }
			},
			{
				path: 'payments',
				component: PaymentsComponent,
                data: { animation: 'SlidePage' }
			},
			{
				path: 'payments/settings',
				component: PaymentsSettingsComponent,
                data: { animation: 'SlidePage2' }
			},
			{
				path: 'payments/:pid',
				component: PaymentComponent,
                data: { animation: 'SlidePage2' }
			},
            {
				path: 'mandateCreationSucceeded', // Mandate creation callback page
				component: ProcessPaymentAfterMandateCreationComponent
			},
			{
				path: 'maintenances',
				component: MaintenancesComponent,
                data: { animation: 'SlidePage' }
			},
			{
				path: 'maintenances/add',
				component: MaintenanceAddComponent,
                data: { animation: 'SlidePage2' }
			},
			{
				path: 'maintenances/:mid',
				component: MaintenanceComponent,
                data: { animation: 'SlidePage2' }
			},
			{
				path: 'chat',
				component: ChatComponent,
                data: { animation: 'SlidePage' }
			},
			{
				path: 'communications',
				component: ChatComponent,
                data: { animation: 'SlidePage' }
			},
            {
				path: 'files',
				component: FilesComponent,
                data: { animation: 'SlidePage' }
			},
            /*{
				path: 'contact',
				component: ContactUsComponent,
                data: { animation: 'SlidePage' }
			},*/
            {
				path: 'error',
				component: ErrorPageComponent,
                data: { animation: 'SlidePage' }
			}
        ]
	},

    { path: '**', redirectTo: '/' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'top',
    relativeLinkResolution: 'legacy',
    paramsInheritanceStrategy: 'always'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
