import { EventEmitter, Injectable, NgZone, OnInit } from '@angular/core';

import { getAuth, onAuthStateChanged, signInWithEmailAndPassword, GoogleAuthProvider, FacebookAuthProvider, OAuthProvider, EmailAuthProvider, UserCredential, signInWithPopup, signInWithCustomToken, signOut, reauthenticateWithCredential, updatePassword, User, Auth, initializeAuth, indexedDBLocalPersistence, signInWithCredential, sendPasswordResetEmail } from "firebase/auth";
import { authState } from 'rxfire/auth';

import { ActivatedRouteSnapshot, Router } from '@angular/router';

import { filter, map, Observable, of, ReplaySubject, switchMap, take } from 'rxjs';
import { GoogleAnalyticsService } from 'ngx-google-analytics';

import { v4 as uuidv4 } from 'uuid';

import { FirebaseAuthentication, SignInResult, User as NativePluginUser } from '@robingenz/capacitor-firebase-authentication';
import { Capacitor } from '@capacitor/core';

import { firebaseApp } from '../app.module'
import { Location } from '@angular/common';


@Injectable({
    providedIn: 'root'
})
export class AuthService {
	
    private onAuthAndUserValidated = new EventEmitter<boolean>();

	public routeToRedirect: ActivatedRouteSnapshot | string = '/';
	public firstAuthCheckMade = false;

	public loginEmail: string = '';
    private userId: string = '';

    private preferredUserId: ReplaySubject<string> = new ReplaySubject<string>();

    public isChangingPassword : boolean = false;
	public currentSession = uuidv4();

    public passwordlessLoginTarget: string = 'choosepassword';

    auth : Auth;

	constructor(
		private router: Router,
        public ngZone: NgZone,
        private readonly gaService: GoogleAnalyticsService,
        private readonly location: Location
	) {

        if (Capacitor.isNativePlatform()) {
            this.auth = initializeAuth(firebaseApp, {
              persistence: indexedDBLocalPersistence
            });
        } else {
            this.auth = getAuth();
        }

        onAuthStateChanged(this.auth, (user) => {

            this.firstAuthCheckMade = true;
            this.userLogStatusChanged(user);
        });

        /*
        FirebaseAuthentication.getCurrentUser().then(user => {
            if (!!user) {
                this.userLogStatusChanged(user.user);
            } else {
                this.userLogStatusChanged(null);
            }
        });

        FirebaseAuthentication.addListener('authStateChange', (change) => {
            console.log("NATIVE AUTH PLUGIN STATE CHANGED");
            console.log(change);
        });
        */
	}

    private userLogStatusChanged (user: User | NativePluginUser | null) {

        if (user) {
            console.log("Auth state: LOGGED IN - " + user.uid);
            sessionStorage.setItem('user', JSON.stringify(user));

            this.loginEmail = user.email ?? "";

            if (this.router.url.startsWith('/passwordlessLogin')) {
                console.log("Logged in with passwordless method");
                this.router.navigate([this.passwordlessLoginTarget]);
            
            } else if (this.router.url.startsWith('/login') || this.router.url.startsWith('/loading')) {
                
                this.router.navigate(['/']);
            }

            // Save current UserID
			this.userId = user.uid;
            this.preferredUserId.next(this.userId);
            
        } else {
            console.log("Auth state: NOT LOGGED IN");
            sessionStorage.setItem('user', '');

            this.onAuthAndUserValidated.emit(false);

            const currentPath = this.location.path();
            
            console.log(currentPath);

            if (currentPath.includes('token')) {
                const retrievedQueryParams = currentPath.substring(currentPath.indexOf('?'));
                const urlParams = new URLSearchParams(retrievedQueryParams);
                const token = urlParams.get('token');
                const target = urlParams.get('target');
                const unitId = urlParams.get('unitId');
                this.router.navigate(['/passwordlessLogin'], {
                    queryParams: { token, target, unitId },
                    queryParamsHandling: 'merge'
                });
                return;
            }


            if (
                !this.router.url.includes('login') &&
                !this.router.url.includes('passwordrecovery') &&
                !this.router.url.includes('passwordlessLogin') &&
                !this.isChangingPassword
            ) {

                console.log(this.router.url);
                
                this.router.navigate(['/login']);
            }

            this.userId = '';
            this.preferredUserId.next('');
        }
    }

	login(email: string, password: string) : Promise<any> {
		return new Promise((resolve, reject) => {
			
            this.onAuthAndUserValidated.subscribe((isLoggedIn : boolean) => {
				if (!isLoggedIn) {
					reject(1);
				}
			});

            signInWithEmailAndPassword(this.auth, email, password)
                .then(res => {
                    resolve(0);
                })
                .catch(err => {
                    reject(0);
                });
		});
	}

    facebookLogIn() {

        if (Capacitor.isPluginAvailable('FirebaseAuthentication')) {
            console.log("CAPACITOR PLUGIN FACEBOOK AUTH");
            FirebaseAuthentication.signInWithFacebook().then(res => {

                if (!!res && !!res.credential && !!res.credential.idToken) {
                    const credential = FacebookAuthProvider.credential(res.credential?.idToken);
                
                    signInWithCredential(this.auth, credential).then(() => {
                        this.gaService.gtag('event', 'user_login', {
                            'event_label': 'User login',
                            'event_category': 'login',
                            'method': 'facebook'
                        });
                    });
                }
            });

        } else {

            console.log("WEB FACEBOOK AUTH");
            this.socialLogin(new FacebookAuthProvider());
        }
    }

    googleLogIn() {

        if (Capacitor.isPluginAvailable('FirebaseAuthentication')) {
            console.log("CAPACITOR PLUGIN GOOGLE AUTH");
            FirebaseAuthentication.signInWithGoogle().then(res => {

                if (!!res && !!res.credential && !!res.credential.idToken) {
                    const credential = GoogleAuthProvider.credential(res.credential?.idToken);
                
                    signInWithCredential(this.auth, credential).then(() => {
                        this.gaService.gtag('event', 'user_login', {
                            'event_label': 'User login',
                            'event_category': 'login',
                            'method': 'google'
                        });
                    });
                }
            });

        } else {

            console.log("WEB GOOGLE AUTH");
            this.socialLogin(new GoogleAuthProvider());
        }
    }

    appleLogIn() {

        if (Capacitor.isPluginAvailable('FirebaseAuthentication')) {
            
            console.log("CAPACITOR PLUGIN APPLE AUTH");
            FirebaseAuthentication.signInWithApple().then(res => {

                if (!!res && !!res.credential && !!res.credential.idToken && !!res.credential.nonce) {
                    const provider = new OAuthProvider('apple.com');
                    const credential = provider.credential({
                        idToken: res.credential?.idToken,
                        rawNonce: res.credential?.nonce,
                    });
                    
                    signInWithCredential(this.auth, credential).then(() => {
                        this.gaService.gtag('event', 'user_login', {
                            'event_label': 'User login',
                            'event_category': 'login',
                            'method': 'apple'
                        });
                    });
                }
            });

        } else {

            console.log("WEB APPLE AUTH");
            this.socialLogin(new OAuthProvider('apple.com'));
        }
    }

    socialLogin(provider : any) : Promise<UserCredential> {
        
        return signInWithPopup(this.auth, provider);
    }

	passwordlessLogin(token: string, passwordlessLoginTarget?: string) {
        if (passwordlessLoginTarget){
            this.passwordlessLoginTarget = passwordlessLoginTarget;
        }
        return signInWithCustomToken(this.auth, token);
	}

    isLoggedIn() {
		const user = sessionStorage.getItem('user');
		return !!user && user != '';
	}

	logout() {
        
        signOut(this.auth).then(() => {

			sessionStorage.removeItem('user');
			sessionStorage.removeItem('isValidLandlord');

			this.router.navigate(['/login']);

            this.gaService.gtag('event', 'user_logout', {
                'event_label': 'User logout',
                'event_category': 'login'
            });
		});
	}

    changePassword(oldPswd : string, newPswd : string) : Promise<void> {

        return new Promise(async (resolve, reject) => {

            const user = this.auth.currentUser;
            
            if (!!user) {
                
                const cred = EmailAuthProvider.credential(this.loginEmail, oldPswd);

                reauthenticateWithCredential(user, cred).then(() => {

                    updatePassword(user, newPswd).then(() => {
                        resolve();
                    }).catch(() => {
                        reject();
                    });

                }).catch(() => {
                    reject();
                });

            } else {
                reject();
            }
        });
    }

	getTokenHeader(): Observable<string> {
        return authState(this.auth).pipe(switchMap(user => (user ? user.getIdToken() : '')));
	}

	getUserId(): Observable<string> {
		
        return authState(this.auth).pipe(switchMap(user => {

            if (user) {
                if (this.isGodUser()) {

                    return this.preferredUserId;

                } else {
                    return of(user.uid);
                }

            } else {
                return of('');
            }
        }), filter(it => !!it));
	}
    
    // TODO: !!assumption!! the user comes straight from passwordless login. Its session is valid and they don't need to reauthenticate
    chooseNewPassword(pswd: string) {
		return new Promise((resolve, reject) => {

            const user = this.auth.currentUser;
            
            if (!!user) {

                updatePassword(user, pswd).then(() => {
                    resolve(0);
                }).catch(() => {
                    reject(1);
                });
            
            } else {
                reject(0);
            }
		});
	}

	recoverPassword(email: string, url: string) {
		return new Promise((resolve, reject) => {
			sendPasswordResetEmail(this.auth, email, { url })
				.then(res => {
					resolve(0);
				})
				.catch(err => {
					reject(0);
				});
		});
	}

    public isGodUser(): boolean {
		return this.userId.startsWith('rm_system_admin_');
	}

    public changeUserForGodUser(userId: string) {
		if (this.isGodUser()) {
            this.preferredUserId.next(userId);
		}
	}
}