<!--
<input
    type="file"
    accept=".jpg,.jpeg,.png"
    multiple
    (change)="uploadFilesByClick($event, true)"
    #fileFront
    style="display: none"
/>

<input
    type="file"
    accept=".jpg,.jpeg,.png"
    multiple
    (change)="uploadFilesByClick($event, false)"
    #fileBack
    style="display: none"
/>

<input
    type="file"
    accept=".pdf"
    multiple
    (change)="uploadFilesByClickPdf($event)"
    #filePdf
    style="display: none"
/>
-->

<div *ngIf="!isLoading" fxLayout="column" fxLayoutAlign="flex-start center">
    <div *ngIf="setupNeeded && landlordEpaymentsConfigured" style="margin: 20px 0 0 0">
        <app-roommate-tabs (clickedOption)="onSectionTabClicked($event)" [options]="sectionTabs"></app-roommate-tabs>
        <div class="sep-row"></div>
    </div>



    <div *ngIf="showSetup()" fxLayout="column" fxLayoutAlign="flex-start center" class="payments-setup-content" [ngClass]="{'payments-setup-content-desk': !mobile, 'payments-setup-content-mobile': mobile }">
        
        <div fxLayout="row" fxLayoutAlign="center center" style="width: 100%;">

            <div class="steps-container" [ngClass]="{'mobile': mobile }" [fxFlex]="mobile ? '100%' : '80%'" fxLayout="row" fxLayoutAlign="space-between center"
            [ngStyle]="{'justify-content': allowedPaymentMethods === 'only_credit_card' || allowedPaymentMethods === 'none' ? 'center' : 'flex-start' }" >
                <div class="step-item active" [ngClass]="{'fill': curStep > 1 }">
                    <p *ngIf="curStep === 1" class="semi-bold-20 no-margin color-green">1</p>
                    <img *ngIf="curStep > 1" src="../../assets/img/rm_icon_step_completed.svg" />
                </div>
    
                <div class="step-item" [ngClass]="{'inactive': curStep < 2, 'active': curStep >= 2, 'fill': curStep > 2 }" *ngIf="allowedPaymentMethods !== 'only_credit_card' && allowedPaymentMethods !== 'none'">
                    <p *ngIf="curStep <= 2 " class="semi-bold-20 no-margin" [ngClass]="{'color-grey': curStep < 2, 'color-green': curStep === 2 }">2</p>
                    <img *ngIf="curStep > 2" src="../../assets/img/rm_icon_step_completed.svg" />
                </div>

                <!--
                <div class="step-item" [ngClass]="{'inactive': curStep < 3, 'active': curStep === 3 }">
                    <p class="semi-bold-20 no-margin" [ngClass]="{'color-grey': curStep < 3, 'color-green': curStep === 3 }">3</p>
                </div>
-->

                <div class="step-line line-1" [ngClass]="{'inactive': curStep < 2, 'active': curStep >= 2 }" *ngIf="allowedPaymentMethods !== 'only_credit_card' && allowedPaymentMethods !== 'none'"></div>
                <!--<div class="step-line line-2" [ngClass]="{'inactive': curStep < 3, 'active': curStep === 3 }"></div>-->
            </div>
        </div>

        <div [ngClass]="{'payment-form-min-height': !mobile}" style="width: 100%;">
            <p class="text-center" *ngIf="!mobile" [ngClass]="{'regular-20': !mobile, 'regular-15': mobile }" [innerHTML]="'com_hi_x' | translate:{tenantNameSurname: tenantNameSurname}">
            </p>
            <p *ngIf="curStep === 1" [innerHTML]="'payments_setup_desc_1' | translate" class="text-center payments-desc-label" [ngClass]="{'regular-15': !mobile, 'regular-12': mobile, 'payments-desc-label-mobile': mobile }">
            </p>
            <p *ngIf="curStep === 2" [innerHTML]="'payments_setup_desc_2' | translate" class="text-center payments-desc-label" [ngClass]="{'regular-15': !mobile, 'regular-12': mobile, 'payments-desc-label-mobile': mobile }">
            </p>
            <p *ngIf="curStep === 3" [innerHTML]="'payments_setup_desc_3a' | translate" class="text-center" [ngClass]="{'regular-15': !mobile, 'regular-12': mobile }">
            </p>
            <p *ngIf="curStep === 3" [innerHTML]="'payments_setup_desc_3b' | translate" class="text-center payments-desc-label-2" [ngClass]="{'regular-15': !mobile, 'regular-12': mobile, 'italic': !mobile, 'payments-desc-label-mobile': mobile }">
            </p>

            <div *ngIf="curStep === 1" [formGroup]="basicInfoForm" fxLayout="column" style="width: 100%">
                <div fxLayout="row wrap" fxLayoutAlign="space-between center" fxLayotGap="20px" style="width: 100%">
                    <div [fxFlex]="mobile ? '100%' : '32%'" class="field-margin">
                        <mat-form-field appearance="outline" class="field-full-width" hideRequiredMarker>
                            <mat-label>{{ 'com_name' | translate }}</mat-label>
                            <input
                                type="text"
                                matInput
                                [placeholder]="'com_name' | translate"
                                formControlName="name"
                                autocomplete="off"
                            />
                        </mat-form-field>
                    </div>
                    <div [fxFlex]="mobile ? '100%' : '32%'" class="field-margin">
                        <mat-form-field appearance="outline" class="field-full-width" hideRequiredMarker>
                            <mat-label>{{ 'com_surname' | translate }}</mat-label>
                            <input
                                type="text"
                                matInput
                                [placeholder]="'com_surname' | translate"
                                placeholder="Surname"
                                formControlName="surname"
                                autocomplete="off"
                            />
                        </mat-form-field>
                    </div>
                    <div [fxFlex]="mobile ? '100%' : '32%'" class="field-margin">
                        <mat-form-field appearance="outline" class="field-full-width" hideRequiredMarker>
                            <mat-label>{{ 'com_date_of_birth' | translate }}</mat-label>
                            <input
                                matInput
                                [matDatepicker]="birthDate"
                                formControlName="dateOfBirth"
                                autocomplete="off"
                                [max]="today"
                                [placeholder]="'com_date_of_birth' | translate"
                                placeholder="Date of birth"
                                readonly
                                (click)="birthDate.open()"
                            />
                            <mat-datepicker #birthDate></mat-datepicker>
                            <img
                                class="roommate-datepicker-icon"
                                src="./assets/img/ic_datepicker.png"
                                (click)="birthDate.open()"
                            />
                        </mat-form-field>
                    </div>
                </div>
                <div fxLayout="row wrap" fxLayoutAlign="space-between center" style="width: 100%">
                    <div [fxFlex]="mobile ? '100%' : '32%'" class="field-margin">
                        <mat-form-field appearance="outline" class="field-full-width" hideRequiredMarker>
                            <mat-label>{{ 'com_email' | translate }}</mat-label>
                            <input
                                type="text"
                                matInput
                                [placeholder]="'com_email' | translate"
                                placeholder="Email"
                                formControlName="email"
                                autocomplete="off"
                            />
                        </mat-form-field>
                    </div>
                    <div [fxFlex]="mobile ? '100%' : '32%'" style="position: relative" class="field-margin">
                        <mat-select-country
                            style="margin-bottom: 20px; width: 100%;"
                            appearance="outline"
                            [itemsLoadSize]="20"
                            [label]="'com_nationality' | translate"
                            formControlName="nationality"
                        >
                        </mat-select-country>
                    </div>
                    <div [fxFlex]="mobile ? '100%' : '32%'" style="position: relative" class="field-margin">
                        <mat-select-country
                            style="margin-bottom: 20px; width: 100%;"
                            appearance="outline"
                            [itemsLoadSize]="20"
                            [label]="'com_country_of_residence' | translate"
                            formControlName="countryOfResidence"
                        >
                        </mat-select-country>
                    </div>
                </div>
            </div>

            <div *ngIf="curStep === 2 && (allowedPaymentMethods === 'only_mandate' || allowedPaymentMethods === 'both')" [formGroup]="bankInfoForm" fxLayout="column" style="width: 100%">
                <div fxLayout="row wrap" fxLayoutAlign="center center" [fxLayoutGap]="mobile ? '0px' : '16px'" style="width: 100%">
                    <div [fxFlex]="mobile ? '100%' : '32%'" class="field-margin">
                        <mat-form-field appearance="outline" class="field-full-width" hideRequiredMarker>
                            <mat-label>{{ 'com_ordering_name' | translate }}</mat-label>
                            <input
                                type="text"
                                matInput
                                [placeholder]="'com_ordering_name' | translate"
                                placeholder="Ordering name"
                                formControlName="beneficiary"
                                autocomplete="off"
                            />
                        </mat-form-field>
                    </div>
                    <div [fxFlex]="mobile ? '100%' : '32%'" class="field-margin">
                        <mat-form-field appearance="outline" class="field-full-width" hideRequiredMarker>
                            <mat-label>{{ 'com_up_iban' | translate }}</mat-label>
                            <input
                                type="text"
                                matInput
                                [placeholder]="'com_up_iban' | translate"
                                placeholder="IBAN"
                                formControlName="iban"
                                autocomplete="off"
                            />
                        </mat-form-field>
                    </div>
                </div>

                <div fxLayout="row wrap" fxLayoutAlign="center center" style="width: 100%">
                    <div style="margin-top: 10px;" [ngStyle]="{'width': mobile ? '100%' : 'calc(64% + 20px)' }">
                        <app-address-form
                            #address_selector
                            [required]="true"
                            [inputAddressData]="userPaymentInfo?.bankAccountInfo?.data?.address || invoiceData"
                            (addressDataChange)="onAddressUpdated($event)"
                        ></app-address-form>
                    </div>
                </div>
            </div>

            <!--
            <div *ngIf="curStep === 3 && (allowedPaymentMethods === 'only_mandate' || allowedPaymentMethods === 'both')" fxLayout="column" fxLayoutAlign="flex-start center" style="width: 100%">
                <div *ngIf="!mobile" fxLayout="row" fxLayoutAlign="center center" style="width: 100%;">
                    <mat-radio-group
                        fxLayout="row"
                        fxLayoutAlign="center center"
                        [formControl]="kycDocumentType"
                        style="margin-top: -20px; margin-bottom: 40px; width: 100%;"
                        (change)="kycDocumentTypeChanged($event)"
                    >
                        <div fxFlex="50%" fxLayout="row" fxLayoutAlign="flex-end center">
                            <mat-radio-button class="regular-15 color-grey" [value]="'pics'" style="margin-right: 30px;" [labelPosition]="'before'">
                                {{ 'kyc_doc_selection_pictures' | translate }}
                            </mat-radio-button>
                        </div>
                        <div fxFlex="50%" fxLayout="row" fxLayoutAlign="flex-start center">
                            <mat-radio-button class="regular-15 color-grey" [value]="'pdf'" style="margin-left: 30px;">
                                {{ 'kyc_doc_selection_pdf' | translate }}
                            </mat-radio-button>
                        </div>
                    </mat-radio-group>
                </div>
                
                <div *ngIf="!mobile && kycDocumentType.value === 'pics'" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="60px" style="width: 100%;">
                    <div
                        class="document-upload-div"
                        [ngClass]="{'dropping': documentUploadStatus[0].isDroppingFile }"
                        appDragDrop
                        (onFileDragging)="fileDraggingStatusUpdated($event, true)"
                        (onFileDropped)="uploadFilesByDrop($event, true)"
                        (click)="uploadDocument(true)"
                    >
                        <p *ngIf="documentUploadStatus[0].fileName === ''" class="semi-bold-20 no-margin text-center">{{ 'com_up_front' | translate }}</p>
                        <p *ngIf="documentUploadStatus[0].fileName !== ''" class="semi-bold-12 no-margin text-center">{{ documentUploadStatus[0].fileName }}</p>
                    </div>
                    <div
                        class="document-upload-div"
                        [ngClass]="{'dropping': documentUploadStatus[1].isDroppingFile }"
                        appDragDrop
                        (onFileDragging)="fileDraggingStatusUpdated($event, false)"
                        (onFileDropped)="uploadFilesByDrop($event, false)"
                        (click)="uploadDocument(false)"
                    >
                        <p *ngIf="documentUploadStatus[1].fileName === ''" class="semi-bold-20 no-margin text-center">{{ 'com_up_back' | translate }}</p>
                        <p *ngIf="documentUploadStatus[1].fileName !== ''" class="semi-bold-12 no-margin text-center">{{ documentUploadStatus[1].fileName }}</p>
                    </div>
                </div>
                <div *ngIf="!mobile && kycDocumentType.value === 'pdf'" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="60px" style="width: 100%;">
                    <div
                        class="document-upload-div"
                        [ngClass]="{'dropping': documentUploadStatus[0].isDroppingFile }"
                        appDragDrop
                        (onFileDragging)="fileDraggingStatusUpdatedPdf($event)"
                        (onFileDropped)="uploadFilesByDropPdf($event)"
                        (click)="uploadDocumentPdf()"
                    >
                        <p *ngIf="documentUploadStatus[0].fileName === ''" class="semi-bold-20 no-margin text-center">{{ 'com_up_pdf_file' | translate }}</p>
                        <p *ngIf="documentUploadStatus[0].fileName !== ''" class="semi-bold-12 no-margin text-center">{{ documentUploadStatus[0].fileName }}</p>
                    </div>
                </div>

                <div *ngIf="!mobile && kycDocumentType.value === 'pics'" fxLayout="row" fxLayoutAlign="center flex-start" fxLayoutGap="40px" style="width: 100%; margin-top: 50px;">

                    <div fxFlex="25%">
                        <img style="width:100%" src="../../assets/img/kyc-img-good.png" />
                        <p class="regular-10 no-margin v-spacing text-center" [innerHTML]="'kyc_guide_img_good' | translate"></p>
                    </div>
                    <div fxFlex="25%">
                        <img style="width:100%" src="../../assets/img/kyc-img-cutoff.png" />
                        <p class="regular-10 no-margin v-spacing text-center">{{ 'kyc_guide_img_cutoff' | translate }}</p>
                    </div>
                    <div fxFlex="25%">
                        <img style="width:100%" src="../../assets/img/kyc-img-glare.png" />
                        <p class="regular-10 no-margin v-spacing text-center">{{ 'kyc_guide_img_glare' | translate }}</p>
                    </div>
                    <div fxFlex="25%">
                        <img style="width:100%" src="../../assets/img/kyc-img-blur.png" />
                        <p class="regular-10 no-margin v-spacing text-center">{{ 'kyc_guide_img_blur' | translate }}</p>
                    </div>
                </div>

                <div *ngIf="mobile" fxLayout="column" fxLayoutAlign="flex-start flex-start" style="width: 100%;">
                    <mat-radio-group
                        fxLayout="column"
                        fxLayoutAlign="flex-start flex-start"
                        fxLayoutGap="20px"
                        [formControl]="kycDocumentType"
                        style="margin-top: -20px; margin-bottom: 40px; width: 100%;"
                        (change)="kycDocumentTypeChanged($event)"
                    >
                        <mat-radio-button class="regular-15 color-grey" [value]="'pics'">
                            {{ 'kyc_doc_selection_pictures' | translate }}
                        </mat-radio-button>
                        
                        <mat-radio-button class="regular-15 color-grey" [value]="'pdf'">
                            {{ 'kyc_doc_selection_pdf' | translate }}
                        </mat-radio-button>
                    </mat-radio-group>
                </div>

                <div *ngIf="mobile && kycDocumentType.value === 'pics'" fxLayout="row" fxLayoutAlign="center center" style="width: 100%; max-width: 100%;">
                    <div
                        class="document-upload-div-mobile"
                        [ngClass]="{'dropping': documentUploadStatus[0].isDroppingFile }"
                        appDragDrop
                        (onFileDragging)="fileDraggingStatusUpdated($event, true)"
                        (onFileDropped)="uploadFilesByDrop($event, true)"
                        (click)="uploadDocument(true)"
                    >
                        <p *ngIf="documentUploadStatus[0].fileName === ''" class="semi-bold-20 no-margin text-center">{{ 'com_up_front' | translate }}</p>
                        <p *ngIf="documentUploadStatus[0].fileName !== ''" class="semi-bold-12 no-margin text-center">{{ documentUploadStatus[0].fileName }}</p>
                    </div>
                </div>
                <div *ngIf="mobile && kycDocumentType.value === 'pics'" fxLayout="row" fxLayoutAlign="center center" style="width: 100%; max-width: 100%; margin-top: 30px; margin-bottom: 32px;">
                    <div
                        class="document-upload-div-mobile"
                        [ngClass]="{'dropping': documentUploadStatus[1].isDroppingFile }"
                        appDragDrop
                        (onFileDragging)="fileDraggingStatusUpdated($event, false)"
                        (onFileDropped)="uploadFilesByDrop($event, false)"
                        (click)="uploadDocument(false)"
                    >
                        <p *ngIf="documentUploadStatus[1].fileName === ''" class="semi-bold-20 no-margin text-center">{{ 'com_up_back' | translate }}</p>
                        <p *ngIf="documentUploadStatus[1].fileName !== ''" class="semi-bold-12 no-margin text-center">{{ documentUploadStatus[1].fileName }}</p>
                    </div>
                </div>
                <div *ngIf="mobile && kycDocumentType.value === 'pdf'" fxLayout="row" fxLayoutAlign="center center" style="width: 100%; max-width: 100%;">
                    <div
                        class="document-upload-div-mobile"
                        [ngClass]="{'dropping': documentUploadStatus[0].isDroppingFile }"
                        appDragDrop
                        (onFileDragging)="fileDraggingStatusUpdatedPdf($event)"
                        (onFileDropped)="uploadFilesByDropPdf($event)"
                        (click)="uploadDocumentPdf()"
                    >
                        <p *ngIf="documentUploadStatus[0].fileName === ''" class="semi-bold-20 no-margin text-center">{{ 'com_up_pdf_file' | translate }}</p>
                        <p *ngIf="documentUploadStatus[0].fileName !== ''" class="semi-bold-12 no-margin text-center">{{ documentUploadStatus[0].fileName }}</p>
                    </div>
                </div>

                <div *ngIf="mobile && kycDocumentType.value === 'pics'" fxLayout="row" fxLayoutAlign="center flex-start" fxLayoutGap="30px" style="width: 100%; margin-top: 35px;">

                    <div fxFlex="50%">
                        <img style="width:100%" src="../../assets/img/kyc-img-good.png" />
                        <p class="regular-10 no-margin v-spacing text-center" [innerHTML]="'kyc_guide_img_good' | translate"></p>
                    </div>
                    <div fxFlex="50%">
                        <img style="width:100%" src="../../assets/img/kyc-img-cutoff.png" />
                        <p class="regular-10 no-margin v-spacing text-center">{{ 'kyc_guide_img_cutoff' | translate }}</p>
                    </div>
                </div>

                <div *ngIf="mobile && kycDocumentType.value === 'pics'" fxLayout="row" fxLayoutAlign="center flex-start" fxLayoutGap="30px" style="width: 100%; margin-top: 20px;">
                
                    <div fxFlex="50%">
                        <img style="width:100%" src="../../assets/img/kyc-img-glare.png" />
                        <p class="regular-10 no-margin v-spacing text-center">{{ 'kyc_guide_img_glare' | translate }}</p>
                    </div>
                    <div fxFlex="50%">
                        <img style="width:100%" src="../../assets/img/kyc-img-blur.png" />
                        <p class="regular-10 no-margin v-spacing text-center">{{ 'kyc_guide_img_blur' | translate }}</p>
                    </div>
                </div>


                <p *ngIf="hasFileSizeError" class="regular-15 color-red text-center">{{ 'upload_file_err_size' | translate }}</p>
            </div>
            -->
            
            <div fxLayout="row" fxLayoutAlign="space-between center" style="padding-bottom: 40px;" [ngStyle]="{'margin-top': mobile ? '20px' : '40px'}">
                <div *ngIf="curStep > 1" style="width: 100%;">
                    <button
                        [fxFlex]="mobile ? '120px' : '150px'"
                        mat-button
                        class="button-neutral"
                        [ngClass]="{'button-small': mobile }"
                        (click)="backStepClick()">
                        {{ 'com_back' | translate }}
                    </button>
                </div>
                <div *ngIf="curStep < 2 && (allowedPaymentMethods !== 'only_credit_card' && allowedPaymentMethods !== 'none')" fxLayoutAlign="flex-end" style="width: 100%">
                    <button
                        [fxFlex]="mobile ? '120px' : '150px'"
                        mat-button
                        class="button-positive"
                        [ngClass]="{'button-small': mobile }"
                        (click)="nextStepClick()">
                        {{ 'com_next' | translate }}
                    </button>
                </div>
                <div *ngIf="curStep === 2 || allowedPaymentMethods === 'only_credit_card' || allowedPaymentMethods === 'none'" fxLayoutAlign="flex-end" style="width: 100%">
                    <button
                        [fxFlex]="mobile ? '120px' : '150px'"
                        mat-button
                        class="button-positive"
                        [ngClass]="{'button-small': mobile }"
                        (click)="nextStepClick()">
                        {{ 'com_save' | translate }}
                    </button>
                </div>
            </div>

            <div *ngIf="(curStep === 2 || curStep === 3) && allowedPaymentMethods === 'both'" fxLayout="row" fxLayoutAlign="space-between center" style="width: 100%; padding-bottom: 40px;" [ngStyle]="{'margin-top': '20px'}">
                <p class="regular-13 no-margin" [innerHTML]="'skip_mandate_info' | translate"></p>

                <button
                    mat-button
                    [fxFlex]="mobile ? '120px' : '150px'"
                    class="button-primary"
                    [ngClass]="{'button-small': mobile }"
                    (click)="skipStepClick()">
                    {{ 'com_skip' | translate }}
                </button>
            </div>

            <div *ngIf="curStep === 1" fxLayout="row" fxLayoutAlign="space-around center" style="width: 100%; padding-bottom: 40px;" [ngStyle]="{'margin-top': '20px'}">
                <p class="regular-12 no-margin color-1 underlined text-center" (click)="askToBeContactedAsALegalPerson()">{{ 'e_payments_legal_person_setup' | translate }}</p>
            </div>
        </div>
    </div>


    <!--
    <div *ngIf="showKycInProgress()" fxLayout="column" fxLayoutAlign="flex-start center" class="payments-main-content" [ngClass]="{'payments-main-content-desk': !mobile, 'payments-main-content-mobile': mobile }">

        <ng-container *ngIf="kycRefusedCode === ''">

            <div *ngIf="!mobile" fxLayout="row" fxLayoutAlign="flex-start center" class="top-row">
                <img class="img-back" src="../../assets/img/rm_icon_arrow_left.svg" (click)="backClick()" />
                <p class="regular-20 no-margin">{{ 'com_go_back' | translate }}</p>
            </div>
    
            <img class="kyc-in-progress-img" src="../../assets/img/rm_icon_kyc_in_progress.png" [ngClass]="{ 'mobile': mobile }" />
    
            <p class="semi-bold-15 text-center">{{ 'kyc_in_progress_1' | translate }}</p>
        </ng-container>

        <ng-container *ngIf="kycRefusedCode !== ''">
            
            <div *ngIf="!mobile" fxLayout="row" fxLayoutAlign="flex-start center" class="top-row">
                <img class="img-back" src="../../assets/img/rm_icon_arrow_left.svg" (click)="backClick()" />
                <p class="regular-20 no-margin">{{ 'com_go_back' | translate }}</p>
            </div>
    
            <img class="kyc-in-error-img" src="../../assets/img/ic_kyc_error.svg" [ngClass]="{ 'mobile': mobile }" />
    
            <p class="semi-bold-15 text-center">{{ 'kyc_failed_1' | translate }}</p>

            <p class="regular-15 text-center" style="font-style: italic;">
                {{ 'kyc_failed_2' | translate }}
                <span *ngIf="kycRefusedCodeFound">{{ 'kyc_error_' + kycRefusedCode | translate }}</span>
                <span *ngIf="!kycRefusedCodeFound">{{ 'kyc_error_SPECIFIC_CASE' | translate }}</span>
            </p>
            <p *ngIf="!kycRefusedCodeFound" class="regular-12" [innerHTML]="'kyc_error_contact_us' | translate"></p>

            <div fxLayout="row" fxLayoutAlign="center center" style="width: 100%;" [ngStyle]="{'margin-top': mobile ? '20px' : '40px'}">
                <button
                    [fxFlex]="mobile ? '120px' : '150px'"
                    mat-button
                    class="button-positive"
                    [ngClass]="{'button-small': mobile }"
                    (click)="restartKycProcessClick()">
                    {{ 'com_next' | translate }}
                </button>
            </div>
        </ng-container>
    </div>
    -->

    

    <div *ngIf="showPaymentsList()" fxLayout="column" fxLayoutAlign="flex-start center" class="payments-main-content" [ngClass]="{'payments-main-content-desk': !mobile, 'payments-main-content-mobile': mobile }">
        
        <div *ngIf="!mobile" fxLayout="row" fxLayoutAlign="space-between center" class="top-row" [ngClass]="{'no-top-margin': setupNeeded && landlordEpaymentsConfigured}">
            <div fxLayout="row" fxLayoutAlign="flex-start center">
                <img class="img-back" src="../../assets/img/rm_icon_arrow_left.svg" (click)="backClick()" />
                <p class="regular-20 no-margin" [innerHTML]="'screenTitles.payments_title' | translate">
                </p>
            </div>
            
            <!--  && !kycInProgress -->
            <img *ngIf="!setupNeeded" class="img-settings" src="../../assets/img/rm_icon_settings.svg" (click)="goToPaymentsSettings()" />
        </div>
        <div *ngIf="mobile" fxLayout="row" fxLayoutAlign="space-between center" class="top-row-mobile" [ngClass]="{'no-top-margin': setupNeeded && landlordEpaymentsConfigured}">
            <p class="regular-15 no-margin" [innerHTML]="'screenTitles.payments_title_2' | translate">
            </p>

            <!--  && !kycInProgress -->
            <img *ngIf="!setupNeeded" class="img-settings" src="../../assets/img/rm_icon_settings.svg" (click)="goToPaymentsSettings()" />
        </div>
        
        <p *ngIf="isBookingPaymentMissing"
            class="regular-15 v-spacing-20" [innerHTML]="'pending_booking_payments' | translate ">
        </p>
        <div [ngClass]="{'full-width': !mobile}">
            <app-roommate-tabs (clickedOption)="onTabClicked($event)" [options]="tabs"></app-roommate-tabs>
            <div class="sep-row"></div>
        </div>

        <mat-card class="no-padding" *ngIf="!mobile">
            <table mat-table [dataSource]="filteredPayments" style="z-index: 10">
                <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef>{{ 'com_up_status' | translate }}</th>
                    <td mat-cell *matCellDef="let p" style="white-space: nowrap">
                        <div *ngIf="getStatus(p) === 'open'" class="rm-tag-view tag-primary">
                            <p class="semi-bold-12 color-white">{{ 'pay_status_open' | translate }}</p>
                        </div>
                        <div *ngIf="getStatus(p) === 'started'" class="rm-tag-view tag-primary">
                            <p class="semi-bold-12 color-white">{{ 'pay_status_started' | translate }}</p>
                        </div>
                        <div *ngIf="getStatus(p) === 'pending'" class="rm-tag-view tag-primary">
                            <p class="semi-bold-12 color-white">{{ 'pay_status_pending' | translate }}</p>
                        </div>
                        <div *ngIf="getStatus(p) === 'close' || getStatus(p) === 'succeeded'" class="rm-tag-view tag-green">
                            <p class="semi-bold-12 color-white">{{ 'pay_status_paid' | translate }}</p>
                        </div>
                        <div *ngIf="getStatus(p) === 'overdue'" class="rm-tag-view tag-red">
                            <p class="semi-bold-12 color-white">{{ 'pay_status_overdue' | translate }}</p>
                        </div>
                        <div *ngIf="getStatus(p) === 'blocked'" class="rm-tag-view tag-loading">
                            <p class="semi-bold-12 color-white">{{ 'pay_status_blocked' | translate }}</p>
                        </div>
                        <div *ngIf="getStatus(p) === 'inactivate'" class="rm-tag-view tag-refusal">
                            <p class="semi-bold-12 color-white">{{ 'pay_status_refusal' | translate }}</p>
                        </div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="desc">
                    <th mat-header-cell *matHeaderCellDef>{{ 'com_up_description' | translate }}</th>
                    <td mat-cell *matCellDef="let p" style="white-space: nowrap">
                        <p class="regular-12 no-margin">{{ getPaymentTitle(p) }}</p>
                    </td>
                </ng-container>
                <ng-container matColumnDef="dueDate">
                    <th mat-header-cell *matHeaderCellDef>{{ 'com_up_due_date' | translate }}</th>
                    <td mat-cell *matCellDef="let p" style="white-space: nowrap">
                        <p class="regular-12 no-margin">{{ p.dueDate | dfnsFormat: 'd MMM yyyy' }}</p>
                    </td>
                </ng-container>
                <ng-container matColumnDef="total">
                    <th mat-header-cell *matHeaderCellDef>{{ 'com_up_total' | translate }}</th>
                    <td mat-cell *matCellDef="let p" style="white-space: nowrap">
                        <p class="semi-bold-12 no-margin">{{ p.amount / 100 | currency: p.currency }}</p>
                    </td>
                </ng-container>
                <ng-container matColumnDef="paid">
                    <th mat-header-cell *matHeaderCellDef>{{ 'com_up_paid' | translate }}</th>
                    <td mat-cell *matCellDef="let p" style="white-space: nowrap">
                        <p *ngIf="p.amountPaid === 0" class="regular-12 no-margin">{{ p.amountPaid / 100 | currency: p.currency }}</p>
                        <p *ngIf="p.amountPaid > 0" class="semi-bold-12 color-green no-margin">{{ p.amountPaid / 100 | currency: p.currency }}</p>
                    </td>
                </ng-container>
                <ng-container matColumnDef="action">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let p" style="white-space: nowrap;" class="cell-align-right">
                        <button *ngIf="(getStatus(p) === 'open' || getStatus(p) === 'overdue') && !setupNeeded && landlordEpaymentsConfigured; else viewPaymentBtn" mat-button class="button-positive button-small" (click)="viewPayment(p)">{{ 'com_pay' | translate }}</button>
                        <ng-template #viewPaymentBtn>
                            <button mat-button class="button-primary button-small" (click)="viewPayment(p)">{{ 'com_view' | translate }}</button>
                        </ng-template>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns"
                ></tr>
            </table>
        </mat-card>

        <ng-container *ngIf="mobile">
            <ng-container *ngFor="let p of filteredPayments">
                <app-mobile-payment-card [payment]="p" [ctaIsViewAndNotPay]="setupNeeded || !landlordEpaymentsConfigured" (viewPayment)="viewPayment($event)"></app-mobile-payment-card>
            </ng-container>
        </ng-container>

        <div *ngIf="payments.length === 0" fxLayout="column" fxLayoutAlign="center center" style="margin-top: 30px;">
            <img src="../../assets/img/RYN-logo.svg" />
            <p class="regular-15 text-center" style="margin-top: 25px; width: 90%;" [innerHTML]="'emptyScreens.empty_payments' | translate:{tenantNameSurname: tenantNameSurname}">
            </p>
        </div>
    </div>
</div>
